import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { toNumber } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Component({
  selector: 'fleet-date-with-time-control',
  templateUrl: './date-with-time-control.component.html',
  styleUrls: ['./date-with-time-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateWithTimeControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateWithTimeControlComponent),
      multi: true,
    },
  ],
})
export class DateWithTimeControlComponent
  implements OnInit, ControlValueAccessor
{
  dateTimeForm: UntypedFormGroup;
  timeErrorState = new TimeErrorStateMatcher();
  dateErrorState = new DateErrorStateMatcher();

  @Input() controlCustomClass: string;

  @Input() wrap = false;
  @Input() minDate: DateTime = null;
  @Input() maxDate: DateTime = null;

  _showNowControl: boolean;
  @Input() set showNowControl(value: boolean) {
    this._showNowControl = value;
    if (value) {
      setTimeout(() => {
        const now = DateTime.now();
        this.dateTimeForm.setValue({
          date: now.toISO(),
          time: now.toFormat('HH:mm'),
        });
      }, 1000);
    }
  }
  get showNowControl() {
    return this._showNowControl;
  }
  nowControl = new UntypedFormControl('NOW');

  _defaultTime: DateTime;
  @Input() set defaultTime(value: DateTime) {
    this._defaultTime = value;
    if (!this.dateTimeForm) {
      this.buildForm();
    }
    if (value && value !== undefined) {
      if (this.defaultTime && this.dateTimeForm.get('time').value) {
        if (!isNaN(this.defaultTime.hour) || !isNaN(this.defaultTime.minute)) {
          this.dateTimeForm.get('time').patchValue({
            hour: this.defaultTime.hour,
            minute: this.defaultTime.minute,
          });
        }

        this.changeDetectorRef.markForCheck();
      }
    }
  }

  get defaultTime() {
    return this._defaultTime;
  }

  _required: boolean;
  @Input() set required(value: boolean) {
    this._required = value;
    if (!this.dateTimeForm) {
      this.buildForm();
    }
    if (value) {
      this.dateTimeForm.get('date').addValidators([Validators.required]);
      this.dateTimeForm.get('time').addValidators([Validators.required]);
    } else {
      this.dateTimeForm.get('date').removeValidators([Validators.required]);
      this.dateTimeForm.get('time').removeValidators([Validators.required]);
    }
    this.dateTimeForm.updateValueAndValidity();
    this.dateTimeForm.get('time').updateValueAndValidity();
    this.dateTimeForm.get('date').updateValueAndValidity();

    this.changeDetectorRef.markForCheck();
  }

  get required() {
    return this._required;
  }

  @Input() timeLabel: string;
  @Input() dateLabel: string;
  @Input() timePlaceholder = 'HH MM';
  @Input() datePlaceholder = 'Choose Date';

  @Input() timePrefix = 'schedule';
  @Input() datePrefix = 'event';

  @Input() labelClass = '';

  _futureDateOnly: boolean;
  @Input() set futureDateOnly(value: boolean) {
    this._futureDateOnly = value;
    if (!this.dateTimeForm) {
      this.buildForm();
    }
    if (value) {
      this.minDate = DateTime.now();
      this.dateTimeForm.addValidators([this.checkFuture]);
    } else {
      this.dateTimeForm.removeValidators([this.checkFuture]);
      this.minDate = null;
    }
    this.dateTimeForm.updateValueAndValidity();
    this.changeDetectorRef.markForCheck();
  }

  get futureDateOnly() {
    return this._futureDateOnly;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,

    private fb: FormBuilder
  ) {}

  buildForm() {
    this.dateTimeForm = this.fb.group({
      date: [null],
      time: [null],
    });
  }

  ngOnInit(): void {
    if (!this.dateTimeForm) {
      this.buildForm();
    }

    this.dateTimeForm.valueChanges.subscribe((value: any) => {
      if (value) {
        let dateTime = DateTime.fromISO(value.date);

        if (value.time && value.time !== 'undefined:undefined') {
          let timeArray: any;
          if (typeof value.time === 'string') {
            //Typed by user
            timeArray = value.time.split(':');
          } else {
            //patched with object
            timeArray = [value.time.hour, value.time.minute];
          }
          dateTime = this.returnTimeWithHoursAndMinutes(
            dateTime,
            toNumber(timeArray[0]),
            toNumber(timeArray[1])
          );
        }
        this.onChange(dateTime);
        this.onTouched();
      }
    });

    this.nowControl.valueChanges.subscribe((value: any) => {
      if (value == 'NOW') {
        const now = DateTime.now();
        this.dateTimeForm.setValue({
          date: now.toISO(),
          time: now.toFormat('HH:mm'),
        });
      }
    });
  }

  returnTimeWithHoursAndMinutes(
    dateTime: DateTime,
    hours: number,
    mins: number
  ) {
    if (!isNaN(hours)) {
      dateTime = dateTime.set({ hour: hours });
    }
    if (!isNaN(mins)) {
      dateTime = dateTime.set({ minute: mins });
    }

    return dateTime;
  }

  onChange: any = (locality: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      let dateTime: DateTime;
      if (typeof value === 'string') {
        dateTime = DateTime.fromISO(value);
      } else {
        dateTime = value as DateTime;
      }
      const hour = dateTime.hour < 10 ? '0' + dateTime.hour : dateTime.hour;
      const minute =
        dateTime.minute < 10 ? '0' + dateTime.minute : dateTime.minute;
      this.dateTimeForm.setValue(
        { date: value, time: hour + ':' + minute },
        { emitEvent: false }
      );
      this.changeDetectorRef.markForCheck();
    } else {
      this.dateTimeForm.reset({}, { emitEvent: false });
    }
  }

  validate(control: AbstractControl) {
    console.log(this.dateTimeForm.valid);

    if (
      this.dateTimeForm.value.date &&
      this.minDate &&
      this.minDate > this.dateTimeForm.value.date
    ) {
      return { dateNotFuture: true };
    }
    return this.dateTimeForm.valid ? null : { error: true };
  }

  reset() {
    this.dateTimeForm.reset({}, { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  markControlAsTouched() {
    this.dateTimeForm.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateTimeForm.disable({ emitEvent: false });
    } else {
      this.dateTimeForm.enable({ emitEvent: false });
    }
    this.dateTimeForm.updateValueAndValidity();
  }

  checkFuture: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const time = group.get('time')?.value as string;
    let date = group.get('date')?.value;
    let combinedDate = DateTime.now();

    if (typeof date === 'string') {
      date = DateTime.fromISO(date);
    } else {
      date = date as DateTime;
    }
    if (!date) {
      return { invalidDate: true };
    } else {
      if (date.startOf('day') < DateTime.now().startOf('day')) {
        //is date correct?
        return { dateNotFuture: true, timeNotFuture: true };
      } else {
        //time correct?
        if (time) {
          const timeArray = time.split(':');
          combinedDate = this.returnTimeWithHoursAndMinutes(
            date,
            toNumber(timeArray[0]),
            toNumber(timeArray[1])
          );

          if (combinedDate < DateTime.now().minus({ minutes: 1 })) {
            return { timeNotFuture: true };
          } else if (combinedDate < this.minDate) {
            return { timeNotFuture: true };
          } else {
            return null;
          }
        } else {
          return { invalidDate: true };
        }
      }
    }
  };
}

class TimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    formDirective: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      (control.touched && control.pristine && control?.errors?.required) ||
      (control.touched && control.dirty && control?.errors?.required) ||
      (formDirective.form.hasError('timeNotFuture') &&
        !formDirective.form.hasError('dateNotFuture'))
    );
  }
}

class DateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    formDirective: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      formDirective.form.hasError('dateNotFuture') ||
      (control.touched && control.pristine && control?.errors?.required) ||
      (control.touched && control.dirty && control?.errors?.required)
    );
  }
}
