import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validators,
} from '@angular/forms';
import { AttributeModel } from '@fleet/model';
import { DateTime } from 'luxon';

@Component({
  selector: 'fleet-attribute-control',
  templateUrl: './attribute-control.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttributeControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AttributeControlComponent),
      multi: true,
    },
  ],
})
export class AttributeControlComponent implements OnInit, ControlValueAccessor {
  @Input() mode: 'horizontal' | 'verticle' = 'horizontal';
  ATTRIBUTE_TYPES = [
    { value: 'STRING', name: 'String' },
    { value: 'INTEGER', name: 'Integer' },
    { value: 'LONG', name: 'Long' },
    { value: 'DECIMAL', name: 'Decimal' },
    { value: 'LOCAL_DATE', name: 'Local date' },
    { value: 'DATE_TIME', name: 'Date time' },
  ];
  @Output() controllerError = new EventEmitter();

  @Input() defaultTime: DateTime = DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  _readonly: boolean;
  @Input() set readonly(value: boolean) {
    if (value && this.form) {
      this.form.disable();
      this.changeDetectorRef.markForCheck();
    } else if (!value && this.form) {
      this.form.enable();
      this.changeDetectorRef.markForCheck();
    }
  }

  get readonly() {
    return this._readonly;
  }
  type = new UntypedFormControl();
  displayName = new UntypedFormControl();
  value = new UntypedFormControl();

  error: string;

  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      type: [
        null,
        {
          updateOn: 'blur',
          validators: [Validators.required],
        },
      ],
      displayName: [
        null,
        {
          updateOn: 'blur',
          validators: [Validators.required],
        },
      ],
      value: [
        null,
        {
          updateOn: 'blur',
          validators: [Validators.required],
        },
      ],
    });
    this.form.valueChanges.subscribe((attribute: AttributeModel) => {
      this.validate(this.form);
      this.onChange(attribute);
      this.onTouched();

      if (
        attribute.type === 'DATE_TIME' &&
        this.form.get('value').value === null
      ) {
        //default time;
        let date = DateTime.now();
        this.form.get('value').patchValue(date);
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  writeValue(value: AttributeModel): void {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
      if (this.readonly) {
        this.form.disable();
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(c: any): any {
    if (c.valid && c.dirty) {
      //check values

      if (c.value.type && c.value.value && c.value.displayName) {
        switch (c.value.type) {
          case 'STRING':
            //strings can be anything.
            return null;
          case 'INTEGER':
          case 'LONG':
            if (c.value.value % 1 === 0) {
              return null;
            } else {
              this.error = 'Value is not a number';

              return {
                attributeControl: this.error,
              };
            }

          case 'DECIMAL':
            if (parseFloat(c.value.value)) {
              return null;
            } else {
              this.error = 'Value is not a decimal';

              return {
                attributeControl: this.error,
              };
            }

          case 'LOCAL_DATE':
          case 'DATE_TIME':
            if (c.value.value.isValid) {
              return null;
            } else {
              this.error = 'Value is not a date';

              return {
                attributeControl: this.error,
              };
            }

          default:
            return null;
        }
      } else {
        return {
          attributeControl: 'Not a valid attribute',
        };
      }
    } else if (c.invalid) {
      return {
        attributeControl: 'Not a valid attribute',
      };
    } else {
      return null;
    }
  }
}
