import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  AddressModel,
  ApiResponse,
  IssueModel,
  LocationModel,
  LocationSearchResultModel,
  NetworkGroupModel,
  PlaceModel,
  PlanSearchResultModel,
} from '@fleet/model';
import {
  centerOfAustralia,
  displayLinesFromAddress,
  hasRequiredValidator,
  locationModelFromLocationSearchResultModel,
  placeModelToLocationModel,
} from '@fleet/utilities';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LocationSearchState, LocationService } from '../location.service';

import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fleet/fuse';
import { LocationFromMapComponent } from '../location-from-map/location-from-map.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { v4 as uuidv4 } from 'uuid';
import { PlaceApiService } from '@fleet/api';
import { NetworkGroupService } from '@fleet/network-group';
@Component({
  selector: 'fleet-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [
    LocationService,
    MatDialog,

    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlacesAutocompleteComponent),
      multi: true,
    },
  ],
})
export class PlacesAutocompleteComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() mode: 'DEFAULT' | 'FORM' = 'DEFAULT';
  @Input() excludeDisambiguate = false;
  @Input() labelClass: string;
  @Input() label: string;
  @Input() placeHolder = 'Search Address';
  @Input() formFieldClasses = 'w-full';
  @Input() appearance: any = 'fill';
  @ViewChild('input') input: ElementRef;
  @Input() prefixIcon: string;
  @Input() letterPrefixIcon: string;
  @Input() modelType: 'location' | 'place' = 'location';
  @Input() showRemove = false;
  @ViewChild('auto') autocomplete: MatAutocomplete;
  @ViewChild('trigger') autoTrigger: MatAutocompleteTrigger;

  @Input() locationType = 'ANY';
  @Input() locationSearchLatLng = centerOfAustralia;
  @Input() locationSearchCountryCode = 'AUS';
  @Input() checkForHomeAddress = false;
  @Input() locationFromMapDialog = false;

  locationControl = new FormControl();

  _australianSuburbOnly = false;
  @Input() set suburbOnly(value: boolean) {
    this._australianSuburbOnly = value;
    if (this.autoTrigger) {
      this.autoTrigger.closePanel();
    }
  }

  get suburbOnly() {
    return this._australianSuburbOnly;
  }

  selectedCustomLocation: LocationModel;

  unsubscribeAll = new Subject();
  @Output() useMapPickup = new EventEmitter();
  @Output() isAirport = new EventEmitter();
  @Output() remove = new EventEmitter();

  @Input() useMap = false;

  manualEntry = false;

  searching: boolean;
  issues: IssueModel[];
  searchSessionId: string;
  moreThanOnePlaceDetail = false;
  searchParams: Subject<any> = new Subject();
  searchResults: PlaceModel[] = [];
  searchControl = new UntypedFormControl(null);
  location: LocationModel;
  @Input() suburbOnlyClearable = false;

  @Input() customLocationFilterType: string;

  @Input() set readOnly(value: boolean) {
    if (value) {
      this.searchControl.disable({ emitEvent: false });
    } else {
      this.searchControl.enable({ emitEvent: false });
    }
  }

  _customLocations: LocationSearchResultModel[];
  @Input() set customLocations(value: LocationSearchResultModel[]) {
    this._customLocations = value;
    if (value?.length > 0) {
      if (this.customLocationFilterType) {
        this._customLocations = value.filter(
          (l) =>
            l.locationType === this.customLocationFilterType ||
            l.locationType === 'ANY'
        );
      }
    } else {
      if (this.searchControl.value?.organisationGroupLocationId) {
        //clear it
        const cleanedValue = {
          ...this.searchControl.value,
          organisationGroupLocationId: null,
          name: null,
        };
        this.searchControl.setValue(null);

        this.changeDetectorRef.markForCheck();
      }

      // check if place exist and clear
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  get customLocations() {
    return this._customLocations;
  }
  public ngControl: NgControl;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private injector: Injector,
    private placeApiService: PlaceApiService,
    private networkGroupService: NetworkGroupService
  ) {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl);
      if (this.ngControl?.control != null) {
        this.searchControl.setValidators(this.ngControl.control.validator);

        if (this.ngControl.value) {
          this.searchControl.markAsTouched();
          this.searchControl.updateValueAndValidity();
        } else {
          this.searchControl.updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
        }

        this.ngControl.control.statusChanges.subscribe((change: any) => {
          this.searchControl.setValidators(this.ngControl.control.validator);

          if (this.ngControl.control.touched) {
            this.searchControl.markAsTouched();
          }
          if (this.moreThanOnePlaceDetail) {
            this.searchControl.setErrors(
              { 'refine-needed': true },
              { emitEvent: false }
            );
          }
          this.searchControl.updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
          });
          this.changeDetectorRef.markForCheck();
        });
      }
    });
  }

  ngOnInit(): void {
    this.networkGroupService.networkGroupAndServiceArea$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (groupServiceArea: any) => {
          if (groupServiceArea) {
            if (groupServiceArea.serviceArea) {
              //
              this.locationSearchLatLng = {
                latitude: groupServiceArea.serviceArea.point.latitude,
                longitude: groupServiceArea.serviceArea.point.longitude,
              };
            }
            if (groupServiceArea.networkGroup) {
              this.locationSearchCountryCode =
                groupServiceArea.networkGroup.countryCode;
            }
          }
        },
      });

    this.wireSearch();
    this.searchSessionId = uuidv4();
    // this.locationService.locationSearch$
    //   .pipe(takeUntil(this.unsubscribeAll))
    //   .subscribe((locationSearch: LocationSearchState) => {
    //     this.locationSearch = locationSearch;
    //     if (locationSearch.moreThanOnePlaceDetail) {
    //       setTimeout(() => {
    //         this.autoTrigger.openPanel();
    //       }, 1);
    //     }
    //     this.changeDetectorRef.markForCheck();
    //   });

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((val) => typeof val === 'string'),
        debounceTime(400),
        distinctUntilChanged(),
        tap((pipedValue) => {
          this.onTouched();
        }),
        map((pipedValue: any) =>
          pipedValue ? pipedValue.slice().trimStart().trimEnd() : pipedValue
        )
      )
      .subscribe((query) => {
        if (
          query.length === 0 ||
          query === '#manual#' ||
          query === '#useMap#'
        ) {
          this.searchResults = [];
          this.onChange(null);
          this.onTouched();
          this.changeDetectorRef.markForCheck();

          //this.onChange(null);
        } else {
          //here we probably only want to do this if the location not null
          this.onChange(null);
          this.onTouched();
          this.changeDetectorRef.markForCheck();

          const params = {
            query: query,
            locationType: this.locationType,
            latitude: this.locationSearchLatLng
              ? this.locationSearchLatLng.latitude
              : centerOfAustralia.latitude,
            longitude: this.locationSearchLatLng
              ? this.locationSearchLatLng.longitude
              : centerOfAustralia.longitude,
          } as any;
          //Country code defaults to AUS

          if (this.locationSearchCountryCode) {
            params['countryCode'] = this.locationSearchCountryCode;
          } else {
            params['countryCode'] = 'AUS';
          }
          if (this.suburbOnly) {
            params['filterType'] = 'LOCALITY';
          }

          this.searchParams.next(params);
        }
      });

    // this.locationService.selectedPlaceDetail$.subscribe({
    //   next: (place: PlaceModel) => {
    //     if (this.autoTrigger) {
    //       this.autoTrigger.closePanel();
    //     }
    //     if (place) {
    //       this.isAirport.emit(place.placeType === 'AIRPORT');

    //       if (this.modelType === 'location') {
    //         const location = placeModelToLocationModel(place);
    //         this.searchControl.setValue(location, { emitEvent: false });
    //         this.onChange(location);
    //       } else {
    //         this.onChange(place);
    //       }
    //       this.onTouched();
    //     } else {
    //       this.onChange(null);
    //       this.onTouched();
    //     }
    //   },
    // });

    //THIS WAS IN  MASETER COMMENTING OUT
    //       if (this.modelType === 'location') {
    //         const location = placeModelToLocationModel(place);
    //         if (this.mode !== 'DEFAULT') {
    //           this.locationControl.setValue(location.structuredAddress);
    //           this.manualEntry = true;
    //         }
    //         this.onChange(location);
    //       } else {
    //         if (this.mode !== 'DEFAULT') {
    //           this.locationControl.setValue(place.structuredAddress);
    //           this.manualEntry = true;
    //         }
    //         this.onChange(place);
    //       }

    //       this.input.nativeElement.blur();
    //     } else {
    //       this.onChange(null);
    //     }
    //     this.onTouched();
    //   },
    // });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedCustomLocation = null;
    this.moreThanOnePlaceDetail = false;
    if (event.option.value === '#manual#') {
      this.searchResults = [];
      this.changeDetectorRef.markForCheck();
      if (this.useMap) {
        this.useMapPickup.emit();
      } else {
        this.enableManualEntry();
      }
    } else if (event.option.value === '#change#') {
      this.searchResults = [];
      this.changeDetectorRef.markForCheck();
      // this.searchControl.reset();
    } else {
      if (event.option.value.locationId) {
        const location = locationModelFromLocationSearchResultModel(
          event.option.value
        );
        //remove locationId once user selected
        location.locationId = null;
        this.selectedCustomLocation = location;
        this.onChange(location);
        this.onTouched();
      } else {
        if (
          event.option.value.structuredAddress &&
          (event.option.value.latitude ||
            event.option.value.structuredAddress.placeName ===
              'Terminal to be advised')
        ) {
          //set selected place DETAIL
          this.placeDetailSelected(event.option.value);
        } else {
          //set selected place to do detail call
          //Need to get place model?
          this.getPlaceDetail(event.option.value);
        }
      }
    }
  }

  displayFn(place: any) {
    if (place === '#manual#' || place === '#useMap#') {
      return '';
    } else if (place && place.organisationGroupLocationId) {
      return (
        place.name +
        (place.displayLine1
          ? ', ' + place.displayLine1
          : place.address
          ? ', ' + place.address
          : '')
      );
    }

    if (typeof place === 'string') {
      return place ? place : '';
    } else {
      if (this.suburbOnly) {
        return place ? place.displayLine1 : null;
      } else {
        return place
          ? [
              place.displayLine1,
              place.displayLine2 && place.displayLine2.endsWith(', AUS')
                ? place.displayLine2.slice(0, -5)
                : place.displayLine2,
            ]
              .filter(Boolean)
              .join(', ')
          : '';
      }
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  onValidatorChange: any = () => {};

  writeValue(value: LocationModel): void {
    this.location = value;
    this.searchControl.setValue(value, { emitEvent: false });

    // this.input.nativeElement.value = value
    //   ? [value.displayLine1, value.displayLine2].filter(Boolean).join(', ')
    //   : '';
  }

  // if (value) {
  //   if (this.suburbOnly) {
  //     this.locationService.setSelectedPlace(value);
  //   } else {
  //     this.locationService.setSelectedPlaceDetail(value);
  //   }
  //   this.searchControl.setValue(value, { emitEvent: false });
  // } else {
  //   this.searchControl.setValue(null, { emitEvent: false });
  // }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.searchControl.disable({ emitEvent: false })
      : this.searchControl.enable({ emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  withMap() {
    if (this.autoTrigger && this.autoTrigger.panelOpen) {
      this.autoTrigger.closePanel();
    }
    if (this.locationFromMapDialog) {
      const dialog = this.matDialog.open(LocationFromMapComponent, {
        panelClass: 'full-screen',
      });
      if (this.searchControl.value?.latitude) {
        dialog.componentInstance.location = this.searchControl.value;
      } else if (this.locationSearchLatLng) {
        dialog.componentInstance.defaultLatLng = this.locationSearchLatLng;
      }
      dialog.componentInstance.locationConfirmed.subscribe(
        (location: LocationModel) => {
          this.searchControl.setValue(location, { emitEvent: false });

          this.onChange(location);
          this.onTouched();
          dialog.close();
        }
      );

      dialog.componentInstance.cancel.subscribe(() => {
        dialog.close();
      });
    } else {
      this.useMapPickup.emit();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validateStructuredAddress(address: AddressModel) {
    if (!address.streetNumber) {
      return false;
    }
    if (!address.streetName) {
      return false;
    }

    return true;
  }

  enableManualEntry() {
    this.manualEntry = true;
    this.changeDetectorRef.markForCheck();
  }

  locationFromForm(address: AddressModel) {
    this.manualEntry = false;
    this.changeDetectorRef.markForCheck();

    this.onChange({ structuredAddress: address });
    this.onTouched();
    const addressDisplay = displayLinesFromAddress(address);
    setTimeout(() => {
      this.input.nativeElement.value =
        addressDisplay.displayLine1 + ', ' + addressDisplay.displayLine2;
    }, 1);
  }

  autoCompleteClosed() {
    setTimeout(() => {
      if (this.moreThanOnePlaceDetail) {
        // this.ngControl.control.setErrors(
        //   { 'refine-needed': true },
        //   { emitEvent: false }
        // );
        // this.ngControl.control.updateValueAndValidity();
        this.searchControl.setErrors(
          { 'refine-needed': true },
          { emitEvent: false }
        );
        // <<<<<<< HEAD
        this.searchControl.setValue(null, { emitEvent: false });
        // BELOW WAS COMMENTED OUT ON MERGE
        // =======
        //         this.input.nativeElement.value = '';
        //       } else if (
        //         !this.locationService.selectedPlaceDetail$.value &&
        //         !this.selectedCustomLocation &&
        //         !this.searching &&
        //         !this.locationService.loadingPlaceDetail &&
        //         ((this.autocomplete && !this.autocomplete.isOpen) || !this.autocomplete)
        //       ) {
        // >>>>>>> master
        this.searchControl.setErrors(
          { 'refine-needed': true },
          { emitEvent: false }
        );
        this.changeDetectorRef.markForCheck();
      } else {
        this.clearRefineNeededError();
        if (
          !this.selectedCustomLocation &&
          !this.searching &&
          !this.autocomplete.isOpen &&
          !this.searchControl.value?.latitude
        ) {
          this.searchControl.setErrors(
            { 'no-place-detail': true },
            { emitEvent: false }
          );
          this.changeDetectorRef.markForCheck();
        }
      }
    }, 100);
  }

  focus() {
    this.input.nativeElement.focus();
    this.input.nativeElement.value = '';
  }

  changeAutocompleteType(type: string) {
    if (type === 'ALL') {
      this.suburbOnly = false;
    } else if (type === 'SUBURB') {
      this.suburbOnly = true;
    }

    this.changeDetectorRef.markForCheck();
  }

  markControlAsTouched() {
    this.searchControl.markAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  wireSearch() {
    this.searchParams.subscribe((params) => {
      //begin search
      this.searching = true;
      this.moreThanOnePlaceDetail = false;
      this.changeDetectorRef.markForCheck();

      const search = {
        latitude: params.latitude,
        longitude: params.longitude,
        query: params.query,
        locationType: params.locationType,
        sessionId: this.searchSessionId,
        limit: '10',
        offset: '0',
      } as any;

      if (params.filterType) {
        search['filterType'] = params.filterType;
      }
      if (params.countryCode) {
        search['countryCode'] = params.countryCode;
      }

      this.placeApiService.autocompleteSearch(search).subscribe({
        next: (resp: ApiResponse<PlaceModel[]> | any) => {
          this.searchResults = resp.data;
          this.searching = false;
          this.changeDetectorRef.markForCheck();
        },
        error: (error: any) => {
          this.issues = error;
          this.searching = false;
          this.changeDetectorRef.markForCheck();
        },
      });
    });
  }

  getPlaceDetail(place: PlaceModel) {
    this.searching = true;
    this.changeDetectorRef.markForCheck();
    const params = { sessionId: this.searchSessionId } as any;
    if (this.excludeDisambiguate) {
      params['disambiguate'] = false;
    }
    this.placeApiService.getPlace(place.id, params).subscribe({
      next: (resp: ApiResponse<PlaceModel[]>) => {
        this.moreThanOnePlaceDetail = resp.data.length > 1;
        if (resp.data.length > 1) {
          this.searchResults = resp.data;
          this.searching = false;
          this.changeDetectorRef.markForCheck();
          this.autoTrigger.openPanel();
        } else {
          this.placeDetailSelected(resp.data[0]);
        }
      },
    });
  }

  placeDetailSelected(place: PlaceModel) {
    this.isAirport.emit(place.placeType === 'AIRPORT');
    this.clearRefineNeededError();
    this.location = placeModelToLocationModel(place);
    this.searchControl.setValue(this.location, { emitEvent: false });
    this.input.nativeElement.blur();
    this.searching = false;
    this.searchResults = [];
    this.onChange(this.location);
    this.onTouched();
    this.changeDetectorRef.markForCheck();
  }

  clearRefineNeededError() {
    if (this.searchControl.hasError('refine-needed')) {
      const errors = this.searchControl.errors;
      delete errors['refine-needed'];
      this.searchControl.setErrors(errors, { emitEvent: false });
      this.searchControl.updateValueAndValidity({ emitEvent: false });
      this.changeDetectorRef.markForCheck();
    }
  }
}
