import { Inject, Injectable } from '@angular/core';

import {
  AssetGroupModel,
  AssetModel,
  ContractModel,
  DriverModel,
  JobModel,
  NetworkUserModel,
  OperatorModel,
  OperatorUserModel,
  OrganisationGroupModel,
  OrganisationModel,
  OrganisationStaffModel,
  OrganisationUserModel,
  PolicyModel,
  PromotionModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';

import { JobStatusPipe, MobileFormatPipe } from '@fleet/pipes';
import { RulesetModel } from '@fleet/shared';
import {
  entityHeaderFromJob,
  entityHeaderFromTraveller,
  internationalizedDate,
  getOrganisationRoutePrefix,
  titleCaseClean,
} from '@fleet/utilities';
import { BehaviorSubject } from 'rxjs';

import { Breadcrumb, EntityHeader } from '@fleet/model';
import { DateTime } from 'luxon';
import { LocaleService } from '@fleet/locale';

@Injectable({
  providedIn: 'root',
})
export class EntityHeaderService {
  entityHeader: BehaviorSubject<EntityHeader> = new BehaviorSubject(null);
  orgHeader: BehaviorSubject<EntityHeader> = new BehaviorSubject(null);
  operatorHeader: BehaviorSubject<EntityHeader> = new BehaviorSubject(null);
  networkGroupHeader: BehaviorSubject<EntityHeader> = new BehaviorSubject(null);
  fleetProduct: string;
  organisationId: string;
  operatorId: string;
  routeData: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(@Inject('env') env: any, private localeService: LocaleService) {
    this.fleetProduct = env.fleetProduct;
  }

  // luxonFormatPipe = new LuxonFormatPipe();
  jobStatusPipe = new JobStatusPipe();

  mobileFormPipe = new MobileFormatPipe();

  get entityHeader$() {
    return this.entityHeader.asObservable();
  }

  get routeData$() {
    return this.routeData.asObservable();
  }

  get orgHeader$() {
    return this.orgHeader.asObservable();
  }

  get operatorHeader$() {
    return this.operatorHeader.asObservable();
  }

  set organisation(entityHeader: EntityHeader) {
    this.orgHeader.next(entityHeader);
  }

  set operator(entityHeader: EntityHeader) {
    this.operatorHeader.next(entityHeader);
  }

  setEntityHeader(entityHeader: EntityHeader) {
    this.entityHeader.next(entityHeader);
  }

  setOrganisationId(organisationId: string) {
    this.organisationId = organisationId;
  }

  setOperatorId(operatorId: string) {
    this.operatorId = operatorId;
  }

  setEntityHeaderTitleOnly() {}

  setRouteData(routeData: any) {
    this.routeData.next(routeData);
  }
  setEntityHeaderGroupOrganisation(
    group: OrganisationGroupModel,
    pageLabel: string,
    type: string
  ) {
    const orgUrl = getOrganisationRoutePrefix(
      this.fleetProduct,
      group.organisationId
    );
    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Group',
        clickable: true,
        prefixArrow: false,
        link: orgUrl + '/group/search',
      },
      {
        label: group.displayName,
        prefixArrow: true,
        clickable: type === 'settings',
        link: orgUrl + '/group/' + group.organisationGroupId,
      },
    ];
    if (type === 'Settings') {
      breadcrumbs.push({
        label: 'Settings',
        clickable: false,
        prefixArrow: true,
      });
    }
    breadcrumbs.push({
      label: pageLabel,
      prefixArrow: true,
      clickable: false,
    });

    this.entityHeader.next({
      title: group?.displayName,
      dataItems: [
        {
          isChip: true,
          label: titleCaseClean(group.status),
        },

        {
          label: group.code,
          icon: 'groups',
          toolTip: 'Group Code',
        },
        {
          label: titleCaseClean(group.type),
          icon: 'business',
          toolTip: 'Group Type',
        },
      ],
      avatar: {
        image: null,
        initials: group?.displayName[0],
      },
      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderPolicyOrganisation(
    policy: PolicyModel,
    pageLabel: string,
    type: string
  ) {
    const orgUrl = getOrganisationRoutePrefix(
      this.fleetProduct,
      policy.organisationId
    );
    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Policies',
        clickable: true,
        prefixArrow: false,
        link: orgUrl + '/policy/search',
      },
      {
        label: policy.name,
        prefixArrow: true,
        clickable: type === 'settings',
        link: orgUrl + '/policy/' + policy.policyId,
      },
    ];
    if (type === 'Settings') {
      breadcrumbs.push({
        label: 'Settings',
        clickable: false,
        prefixArrow: true,
      });
    }
    breadcrumbs.push({
      label: pageLabel,
      prefixArrow: true,
      clickable: false,
    });
    this.entityHeader.next({
      title: policy.name,
      dataItems: [],

      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderRulesSearchOrganisation(
    policy: PolicyModel,
    ruleset: RulesetModel,
    pageLabel: string,
    type: string
  ) {
    const orgUrl = getOrganisationRoutePrefix(
      this.fleetProduct,
      policy.organisationId
    );

    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Policies',
        clickable: true,
        prefixArrow: false,
        link: orgUrl + '/policy/search',
      },
      {
        label: policy.name,
        prefixArrow: true,
        clickable: true,
        link: orgUrl + '/policy/' + policy.policyId,
      },
      {
        label: 'Versions',
        clickable: true,
        prefixArrow: true,
        link: orgUrl + '/policy/' + policy.policyId + '/version',
      },

      {
        label: ruleset?.description
          ? (ruleset.description.length > 50
              ? ruleset.description.substring(0, 50) + '...'
              : ruleset.description) +
            ' (' +
            titleCaseClean(ruleset.status) +
            ')'
          : '' + titleCaseClean(ruleset.status) + ' version',
        clickable: false,
        prefixArrow: true,
        link:
          orgUrl +
          '/policy/' +
          policy.policyId +
          '/version/' +
          ruleset.reference,
      },
    ];

    const dataItems = [
      {
        label: titleCaseClean(ruleset.status),
        icon: ruleset.status === 'PUBLISHED' ? 'done' : 'pending',
        toolTip: 'Status',
        isChip: true,
      },
    ] as any;

    const effectiveFromLabel = internationalizedDate(
      ruleset.effectiveFrom,
      'DATETIME_SHORT',
      false,
      null,
      this.localeService.getLocale()
    );
    const effectiveToLabel = internationalizedDate(
      ruleset.effectiveTo,
      'DATETIME_SHORT',
      false,
      null,
      this.localeService.getLocale()
    );

    if (ruleset.effectiveFrom && ruleset.effectiveTo) {
      dataItems.push({
        label: `${effectiveFromLabel ? effectiveFromLabel : ''} ${
          effectiveToLabel ? ' - ' + effectiveToLabel : ''
        }`,
        icon: 'event',
        toolTip: 'Effective From/To',
      });
    }

    this.entityHeader.next({
      title: ruleset?.description
        ? ruleset.description.length > 50
          ? ruleset.description.substring(0, 50) + '...'
          : ruleset.description
        : '' + titleCaseClean(ruleset.status) + ' version',
      dataItems: dataItems,

      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderRulesetSearchOrganisation(
    policy: PolicyModel,
    pageLabel: string,
    type: string
  ) {
    const orgUrl = getOrganisationRoutePrefix(
      this.fleetProduct,
      policy.organisationId
    );
    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Policies',
        clickable: true,
        prefixArrow: false,
        link: orgUrl + '/policy/search',
      },
      {
        label: policy.name,
        prefixArrow: true,
        clickable: type === 'settings',
        link: orgUrl + '/policy/' + policy.policyId,
      },
      {
        label: 'Versions',
        clickable: true,
        prefixArrow: true,
        link: orgUrl + '/policy/' + policy.policyId + '/version',
      },
    ];
    this.entityHeader.next({
      title: policy.name,
      dataItems: [],

      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderPromotionModel(
    promotion: PromotionModel,
    pageLabel: string,
    type: string
  ) {
    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Promotions',
        clickable: true,
        prefixArrow: false,
        link: 'settings/promotion/search',
      },
      {
        label: promotion.name,
        prefixArrow: true,
        clickable: true,
        link: 'settings/promotion/' + promotion.promotionId,
      },
    ];

    breadcrumbs.push({
      label: pageLabel,
      prefixArrow: true,
      clickable: false,
    });

    const dataItems: any = [];

    if (promotion.effectiveFrom) {
      const effectiveFromLabel = internationalizedDate(
        promotion.effectiveFrom,
        'CONDENSED_DATE',
        false,
        null,
        this.localeService.getLocale()
      );

      const effectiveToabel = internationalizedDate(
        promotion.effectiveFrom,
        'CONDENSED_DATE',
        false,
        null,
        this.localeService.getLocale()
      );

      dataItems.push({
        label:
          effectiveFromLabel +
          (promotion.effectiveTo ? ' - ' + effectiveToabel : ''),
        icon: 'calendar_today',
        toolTip: 'Effective from / to',
      });
    }

    if (promotion.type) {
      dataItems.push({
        label: promotion.type,
        icon: 'category',
        toolTip: 'Type',
      });
    }

    this.entityHeader.next({
      title: promotion.name,
      dataItems: dataItems,
      avatar: {
        image: null,
        initials: null,
      },
      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderTravellerPersonalProfile(
    traveller: TravellerModel,
    pageLabel: string,
    type: string
  ) {
    if (!traveller) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Travellers',
          clickable: true,
          prefixArrow: false,
          link: '/traveller/search',
        },
        {
          label:
            traveller.firstName +
            ' ' +
            (traveller.lastName ? traveller.lastName : ''),
          prefixArrow: true,
          clickable: type === 'settings',
          link: '/traveller/' + traveller.travellerId,
        },
      ];
      if (type === 'Settings') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      //Add the breadcrumb to go back to profile

      breadcrumbs.push({
        label: 'Profiles',
        prefixArrow: true,
        clickable: true,
        link: '/traveller/' + traveller.travellerId + '/settings/profile',
      });

      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });
      this.entityHeader.next({
        ...entityHeaderFromTraveller(traveller, this.fleetProduct),
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderAssetModel(
    asset: AssetModel,
    pageLabel: string,
    type: string
  ) {
    const breadcrumbs: Breadcrumb[] = [
      {
        label: 'Assets',
        clickable: true,
        prefixArrow: false,
        link: '/hardware/asset',
      },
      {
        label: asset.displayName,
        prefixArrow: true,
        clickable: type === 'SETTINGS',
        link: '/hardware/asset/' + asset.assetId,
      },
    ];
    if (type === 'SETTINGS') {
      breadcrumbs.push({
        label: 'Settings',
        clickable: false,
        prefixArrow: true,
      });
    }
    breadcrumbs.push({
      label: pageLabel,
      prefixArrow: true,
      clickable: false,
    });

    const dataItems: any = [
      {
        isChip: true,
        label: titleCaseClean(asset.assetStatus),
      },
      {
        label: asset.serialNo,
        icon: 'smartphone',
        toolTip: 'Serial No.',
      },
      {
        label: asset.assetGroupDisplayName,
        icon:
          asset?.allocatedTo === 'VEHICLE' || asset?.allocatedTo === 'DRIVER'
            ? 'local_taxi'
            : 'inventory_2',
        toolTip: 'Asset Group',
        link:
          asset?.allocatedTo === 'VEHICLE'
            ? '/vehicle/' + asset.allocatedToId + '/settings/asset'
            : asset?.allocatedTo === 'DRIVER'
            ? '/driver/' + asset.allocatedToId + '/settings/status'
            : null,
      },
    ];

    if (asset.terminalId) {
      dataItems.push({
        label: asset.terminalId,
        icon: 'numbers',
        toolTip: 'TID',
      });
    }

    this.entityHeader.next({
      title: asset.manufacturer + ' ' + asset.model,
      dataItems: dataItems,
      avatar: {
        image: null,
        initials: null,
      },
      breadcrumbs: breadcrumbs,
    });
  }

  setEntityHeaderDriverModel(
    driver: DriverModel,
    pageLabel: string,
    type: string
  ) {
    if (!driver) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Drivers',
          clickable: true,
          prefixArrow: false,
          link: '/driver/search',
        },
        {
          label: driver.firstName + ' ' + driver.lastName,
          prefixArrow: true,
          clickable: type === 'SETTINGS',
          link: '/driver/' + driver.driverId,
        },
      ];
      if (type === 'SETTINGS') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }

      const dateLabel = internationalizedDate(
        driver.taxiAuthorityExpiry,
        'CONDENSED_DATE',
        false,
        null,
        this.localeService.getLocale()
      );

      let panelOne = {
        dataItems: [
          {
            label: driver.taxiAuthorityNumber,
            secondaryLabel: '(exp:' + dateLabel + ')',
            icon: 'assignment_ind',
            toolTip: 'Authority Number',
          },

          {
            label: driver.username,
            icon: 'mail',
            toolTip: driver.username,
            link: 'mailto:' + driver.username,
            externalLink: true,
          },
          {
            dialablePhoneNumber:
              this.fleetProduct === 'HUB'
                ? { phoneNumber: driver.phoneNumber, tooltip: 'Call Driver' }
                : null,
            label: this.mobileFormPipe.transform(driver.phoneNumber),
            icon: 'phone',
            toolTip: 'Phone Number',
            link: 'tel:' + driver.phoneNumber,
            externalLink: true,
          },
        ],
      };

      //shift status

      const panelTwo = {
        dataItems: [
          {
            label: driver.online ? 'Online' : 'Offline',
            icon: 'circle',
            iconStyle: driver.online
              ? 'text-green-400 icon-size-3 items-center w-full mr-2'
              : 'text-red-400 icon-size-3 items-center w-full  ml-1.5 mr-2.5',
          },

          {
            label:
              driver?.device?.deviceOs && driver?.device?.appVersion
                ? driver?.device?.appVersion?.versionName +
                  ' (' +
                  (driver?.device.deviceOs === 'IOS'
                    ? 'iOS'
                    : driver?.device.deviceOs === 'ANDROID'
                    ? 'Android'
                    : driver?.device.deviceOs) +
                  ' ' +
                  driver?.device?.osVersion +
                  ')'
                : 'No device detected',
            icon:
              !driver?.device || driver?.device?.deviceOs === 'UNKNOWN'
                ? 'device_unknown'
                : null,
            svgIcon:
              driver?.device?.deviceOs === 'ANDROID'
                ? 'vendors:android-green'
                : driver?.device?.deviceOs === 'IOS'
                ? 'vendors:apple-black'
                : null,
          },
        ],
      };

      if (driver.shift) {
        if (driver.shift.penaltyType === 'DISPATCH_SUSPENSION') {
          panelTwo.dataItems.push({
            label:
              'Suspended until ' +
              DateTime.fromISO(driver.shift.penaltyEnd.toString()).toFormat(
                'h:mm a'
              ) +
              ' ' +
              DateTime.fromISO(
                driver.shift.penaltyEnd.toString()
              ).toRelativeCalendar(),
            icon: 'warning',
          } as any);
        } else {
          panelTwo.dataItems.push({
            label: 'Shift In Progress',
            icon: 'local_taxi',
          } as any);
        }
      } else {
        panelTwo.dataItems.push({
          label: 'Not currently on a shift',
          icon: 'local_taxi',
        } as any);
      }

      const panels = [panelOne, panelTwo];

      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });
      this.entityHeader.next({
        title: driver.preferredName,
        subtitle:
          driver.firstName +
          (driver.middleName ? ' ' + driver.middleName : '') +
          ' ' +
          driver.lastName,
        avatar: {
          image: driver.driverPhoto ? driver.driverPhoto.url : null,
        },
        subtitleTooltip:
          driver.firstName +
          (driver.middleName ? ' ' + driver.middleName : '') +
          ' ' +
          driver.lastName,
        titleTooltip: driver.preferredName,
        status: driver.status,
        statusLink: '/driver/' + driver.driverId + '/settings/status',
        panels: panels,
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderJobModel(
    job: JobModel,

    pageLabel: string,
    type: string
  ) {
    if (!job) {
      this.entityHeader.next(null);
    } else {
      const orgUrl = getOrganisationRoutePrefix(
        this.fleetProduct,
        this.organisationId
      );

      const header = entityHeaderFromJob(
        job,
        this.fleetProduct,
        true,
        pageLabel,
        orgUrl,
        false
      );
      this.entityHeader.next({ ...header });
    }
  }

  setEntityHeaderTravellerModel(
    traveller: TravellerModel,

    pageLabel: string,
    type: string
  ) {
    if (!traveller) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Travellers',
          clickable: true,
          prefixArrow: false,
          link: '/traveller/search',
        },
        {
          label:
            traveller.firstName +
            ' ' +
            (traveller.lastName ? traveller.lastName : ''),
          prefixArrow: true,
          clickable: type === 'settings',
          link: '/traveller/' + traveller.travellerId,
        },
      ];
      if (type === 'Settings') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });
      this.entityHeader.next({
        ...entityHeaderFromTraveller(traveller, this.fleetProduct),
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderShipmentModel(shipment: AssetGroupModel) {
    if (!shipment) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Shipments',
          clickable: true,
          prefixArrow: false,
          link: '/hardware/shipment',
        },
        {
          label: shipment.displayName
            ? shipment.displayName
            : shipment.description,
          prefixArrow: true,
          clickable: false,
          link: '/hardware/shipment/' + shipment.assetGroupId,
        },
      ];
      const dataItems = [];
      if (shipment.consignmentStatus) {
        dataItems.push({
          isChip: true,
          label: titleCaseClean(shipment.consignmentStatus),
          toolTip: 'Status',
        });
      }
      if (shipment.collectionMethod) {
        dataItems.push({
          icon: 'local_shipping',
          label: titleCaseClean(shipment.collectionMethod),
          toolTip: 'Collection Method',
        });
      }
      if (shipment.consignmentType) {
        dataItems.push({
          icon: 'location_on',
          label: titleCaseClean(shipment.consignmentType),
          toolTip: 'Consignment type',
        });
      }
      if (shipment.consignmentNumber) {
        dataItems.push({
          icon: 'tag',
          label: titleCaseClean(shipment.consignmentNumber),
          toolTip: 'Consignment Number',
        });
      }
      if (shipment.contactName) {
        dataItems.push({
          icon: 'person',
          label: shipment.contactName,
          toolTip: 'Contact Name',
        });
      }
      if (shipment.contactNumber) {
        dataItems.push({
          icon: 'phone',
          label: titleCaseClean(shipment.contactNumber),
          toolTip: 'Contact Number',
        });
      }
      if (shipment.referenceNumber) {
        dataItems.push({
          icon: 'tag',
          label: shipment.referenceNumber,
          toolTip: 'Asset Reference',
        });
      }

      this.entityHeader.next({
        title: shipment.displayName
          ? shipment.displayName
          : shipment.description,
        dataItems: dataItems,

        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderVehicleModel(
    vehicle: VehicleModel,
    pageLabel: string,
    type: string,
    operator?: OperatorModel
  ) {
    if (!vehicle) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Vehicles',
          clickable: true,
          prefixArrow: false,
          link: '/vehicle/search',
        },
        {
          label: vehicle.plate
            ? vehicle?.plate?.plateNumber
            : vehicle?.displayName,
          prefixArrow: true,
          clickable: type === 'settings',
          link: '/vehicle/' + vehicle.vehicleId,
        },
      ];
      if (type === 'Settings') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });

      const dataItems = [
        {
          isChip: true,
          label: titleCaseClean(vehicle.status),
          toolTip: 'Status',
          link: operator
            ? '/operator/' +
              operator.operatorId +
              '/vehicle/' +
              vehicle.vehicleId +
              '/settings/status'
            : '/vehicle/' + vehicle.vehicleId + '/settings/status',
        },
        {
          label: vehicle.make + '/' + vehicle.model,
          icon: 'directions_car',
          toolTip: 'Make/Model',
        },
      ];
      if (vehicle.bodyType) {
        dataItems.push({
          label: vehicle.bodyType,
          icon: 'badge',
          toolTip: 'Body Type',
        });
      }
      if (vehicle.yearOfManufacture) {
        dataItems.push({
          label: vehicle.yearOfManufacture.toString(),
          icon: 'calendar_today',
          toolTip: 'Manufacture Year',
        });
      }
      this.entityHeader.next({
        title: vehicle.plate
          ? vehicle?.plate?.plateNumber
          : vehicle?.displayName,
        dataItems: dataItems,
        avatar: {
          image: vehicle.vehiclePhotoFront
            ? vehicle.vehiclePhotoFront.url
            : null,
          initials: null,
        },
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setOperatorHeaderFromOperatorMode(
    operator: OperatorModel,
    pageLabel: string,
    type: string
  ) {
    if (!operator) {
      this.entityHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Operators',
          clickable: true,
          prefixArrow: false,
          link: '/operator/search',
        },
        {
          label: operator.displayName,
          prefixArrow: true,
          clickable: type === 'SETTINGS',
          link: '/operator/' + operator.operatorId,
        },
      ];
      if (type === 'SETTINGS') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });

      const dataItems: any[] = [];

      dataItems.push({
        isChip: true,
        link: '/operator/' + operator.operatorId + '/settings/status',

        label: titleCaseClean(operator.status),
        toolTip: 'Status',
      });

      if (operator.contactNumber) {
        dataItems.push({
          label: operator.contactNumber,
          icon: 'phone',
          toolTip: 'Phone Number',
        });
      }

      if (operator.contactEmail) {
        dataItems.push({
          label: operator.contactEmail,
          icon: 'mail',
          toolTip: 'Email',
        });
      }

      if (operator.abn) {
        dataItems.push({
          label:
            operator.abn.slice(0, 2) +
            ' ' +
            operator.abn.slice(2, 5) +
            ' ' +
            operator.abn.slice(5, 8) +
            ' ' +
            operator.abn.slice(8, 11),
          icon: 'business',
          toolTip: 'ABN',
        });
      }

      this.operatorHeader.next({
        title: operator.displayName,
        dataItems: dataItems,
        avatar: null,
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setOrgHeaderFromOrganisationModel(
    org: OrganisationModel,
    pageLabel: string,
    type: string
  ) {
    if (!org) {
      this.orgHeader.next(null);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Organisations',
          clickable: true,
          prefixArrow: false,
          link: '/organisation/search',
        },
        {
          label: org.name,
          prefixArrow: true,
          clickable: type === 'SETTINGS',
          link: '/organisation/' + org.organisationId,
        },
      ];
      if (type === 'SETTINGS') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });

      this.orgHeader.next({
        title: org.name,
        dataItems: [
          {
            isChip: true,
            label: titleCaseClean(org.status),
          },
          {
            label: org.organisationCode,
            icon: 'tag',
            toolTip: 'Organisation code',
          },

          {
            label:
              org.abn.slice(0, 2) +
              ' ' +
              org.abn.slice(2, 5) +
              ' ' +
              org.abn.slice(5, 8) +
              ' ' +
              org.abn.slice(8, 11),
            icon: 'business',
            toolTip: 'ABN',
          },
          {
            label: org.emailDomain,
            icon: 'mail',
            toolTip: 'Email',
          },
        ],
        avatar: org.emailDomain
          ? {
              image: org.emailDomain
                ? 'https://logo.clearbit.com/' + org.emailDomain
                : '',
              initials: org.name.substring(0, 1),
            }
          : null,
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderStaffOrganisation(
    staff: OrganisationStaffModel,

    pageLabel: string,
    type: string
  ) {
    if (!staff) {
      this.entityHeader.next(null);
    } else {
      let staffSearchLink;

      const orgUrl = getOrganisationRoutePrefix(
        this.fleetProduct,
        staff.organisationId
      );
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Staff',
          clickable: true,
          prefixArrow: false,
          link: orgUrl + '/staff/search',
        },
        {
          label: staff.firstName + ' ' + staff.lastName,
          prefixArrow: true,
          clickable: type === 'settings',
          link: orgUrl + '/staff/' + staff.organisationStaffId,
        },
      ];
      if (type === 'Settings') {
        breadcrumbs.push({
          label: 'Settings',
          clickable: false,
          prefixArrow: true,
        });
      }
      breadcrumbs.push({
        label: pageLabel,
        prefixArrow: true,
        clickable: false,
      });

      this.entityHeader.next({
        title: staff.firstName + ' ' + staff.lastName,
        dataItems: [
          {
            isChip: true,
            label: titleCaseClean(staff.status),
          },
          {
            label: staff.phoneNumber,
            icon: 'phone',
            toolTip: 'Phone Number',
          },
          { label: staff.email, icon: 'mail', toolTip: 'Email' },
        ],
        avatar: {
          image: null,
          initials: staff.firstName[0] + staff.lastName[0],
        },

        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderNetworkUser(
    user: NetworkUserModel,

    pageLabel: string,
    type: string
  ) {
    if (!user) {
      this.entityHeader.next(null);
    } else {
      let userSearchLink;

      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Users',
          clickable: true,
          prefixArrow: false,
          link: '/settings/security/user',
        },
        {
          label: user.firstName + ' ' + user.lastName,
          prefixArrow: true,
          clickable: type === 'settings',
          link: 'settings/security/user/' + user.networkUserId,
        },
      ];

      //No need to set the 'Settings' breadcrumb anymore.

      this.entityHeader.next({
        title: user.firstName + ' ' + user.lastName,
        dataItems: [
          {
            isChip: true,
            label: titleCaseClean(user.status),
          },
          {
            label: user.phoneNumber,
            icon: 'phone',
            toolTip: 'Phone Number',
          },
          { label: user.username, icon: 'mail', toolTip: 'Email' },
        ],
        avatar: null,
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderContractModel(contract: ContractModel, type: string) {
    if (!contract) {
      this.entityHeader.next(null);
    } else {
      let orgUrl: string;
      if (type === 'organisation') {
        orgUrl = getOrganisationRoutePrefix(
          this.fleetProduct,
          this.organisationId
        );
      }

      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Contracts',
          clickable: true,
          prefixArrow: false,
          link:
            type === 'organisation'
              ? orgUrl + '/settings/contract'
              : type === 'operator'
              ? 'operator/' + this.operatorId + '/dashboard/'
              : type === 'organisation_group'
              ? orgUrl + '/group/NUMBER/settings/contract'
              : null,
        },
        {
          label: contract.name,
          prefixArrow: true,
          clickable: false,
        },
      ];

      this.entityHeader.next({
        title: contract.name,
        dataItems: [
          {
            label: contract.creditLimit,
            icon: 'account_balance_wallet',
            toolTip: 'Credit Limit',
          },
          {
            label: titleCaseClean(contract.creditTerm),
            icon: 'event',
            toolTip: 'Credit terms',
          },
          {
            label: titleCaseClean(contract.status),
            isChip: true,
          },
        ],
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderUserOrganisation(
    user: OrganisationUserModel,

    pageLabel: string,
    type: string
  ) {
    if (!user) {
      this.entityHeader.next(null);
    } else {
      let userSearchLink;
      const orgUrl = getOrganisationRoutePrefix(
        this.fleetProduct,
        user.organisationId
      );
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Users',
          clickable: true,
          prefixArrow: false,
          link: orgUrl + '/settings/user',
        },
        {
          label: user.firstName + ' ' + user.lastName,
          prefixArrow: true,
          clickable: type === 'settings',
          link: orgUrl + '/settings/user/' + user.organisationUserId,
        },
      ];
      //Do not need to set the Settings breadcrumb on Org user anymore.

      this.entityHeader.next({
        title: user.firstName + ' ' + user.lastName,
        dataItems: [
          {
            isChip: true,
            label: titleCaseClean(user.status),
          },
          {
            label: user.phoneNumber,
            icon: 'phone',
            toolTip: 'Phone Number',
          },
          { label: user.username, icon: 'mail', toolTip: 'Email' },
        ],
        avatar: {
          image: null,
          initials: user.firstName[0] + user.lastName[0],
        },
        breadcrumbs: breadcrumbs,
      });
    }
  }

  setEntityHeaderOperatorUser(
    user: OperatorUserModel,
    pageLabel: string,
    type: string
  ) {
    if (!user) {
      this.entityHeader.next(null);
    } else {
      const operatorUrl = this.getOperatorRoutePrefix();
      const breadcrumbs: Breadcrumb[] = [
        {
          label: 'Users',
          clickable: true,
          prefixArrow: false,
          link: operatorUrl + '/settings/user',
        },
        {
          label: user.firstName + ' ' + user.lastName,
          prefixArrow: true,
          clickable: type === 'settings',
          link: operatorUrl + '/settings/user/' + user.operatorUserId,
        },
      ];
      //Do not need to set the 'settings' breadcrumb on an operator user.

      this.entityHeader.next({
        title: user.firstName + ' ' + user.lastName,
        dataItems: [
          {
            isChip: true,
            label: titleCaseClean(user.status),
          },
          {
            label: user.phoneNumber,
            icon: 'phone',
            toolTip: 'Phone Number',
          },
          { label: user.username, icon: 'mail', toolTip: 'Email' },
        ],
        avatar: {
          image: null,
          initials: user.firstName[0] + user.lastName[0],
        },
        breadcrumbs: breadcrumbs,
      });
    }
  }

  getOperatorRoutePrefix() {
    return this.fleetProduct === 'OPERATOR'
      ? ''
      : this.fleetProduct === 'HUB' && this.operatorId
      ? '/operator/' + this.operatorId
      : '';
  }
}
