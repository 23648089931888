import { Component, OnInit } from '@angular/core';
import { VersionUpdateService } from './../version-update.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'fleet-update-banner',
  templateUrl: './update-banner.component.html',
  styleUrls: ['./update-banner.component.scss'],
})
export class UpdateBannerComponent implements OnInit {
  showUpdateAvailable$: Observable<boolean>;
  constructor(private versionUpdateService: VersionUpdateService) {}

  ngOnInit(): void {
    this.showUpdateAvailable$ = this.versionUpdateService.showUpdateAvailable$;
  }

  dismiss() {
    this.versionUpdateService.dismissUpdate();
  }

  activate() {
    this.versionUpdateService.activateUpdate();
  }
}
