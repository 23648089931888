<div
  class="bg-card rounded-md shadow-sm border border-gray-300"
  [ngClass]="{
    'border-gray-500': hostFocused && serviceControl.enabled,
    'opacity-60': serviceControl.disabled,
    'border-red-500': serviceControl.errors && serviceControl.touched
  }"
>
  <div
    class="w-full flex flex-row justify-between items-center py-3 px-4"
    [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
    (click)="serviceControl?.enabled ? toggleList() : null"
  >
    <div class="flex shrink flex-row">
      <mat-icon class="mr-3 icon-size-5 text-hint">local_taxi</mat-icon>
      <div
        [ngClass]="{
          'text-red-500': serviceControl.errors && serviceControl.touched
        }"
      >
        {{ showList ? 'Select Service' : 'Service' }}
      </div>
    </div>
    <mat-icon
      *ngIf="!loading && serviceControl.enabled"
      [svgIcon]="showList ? 'expand_less' : 'expand_more'"
      class="icon-size-5"
    >
    </mat-icon>
    <ng-container *ngIf="loading">
      <mat-progress-spinner
        diameter="18"
        mode="indeterminate"
      ></mat-progress-spinner>
    </ng-container>
  </div>
  <div *ngIf="showList || serviceControl.value" class="border-t-2"></div>

  <fuse-alert
    *ngFor="let alert of issues | alertsFromIssues"
    class="m-1"
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <ng-container *ngIf="showList; else showSelected">
    <ng-container *ngTemplateOutlet="list"></ng-container>
  </ng-container>

  <ng-template #list>
    <fuse-alert *ngIf="!startLocation && !loading" type="warn">
      Please select a start location to see available services
    </fuse-alert>
    <fuse-alert
      *ngIf="
        services.length === 0 && jobOrganisation && previousParams && !loading
      "
      type="warn"
    >
      There are no services available for this class
    </fuse-alert>

    <ul
      #serviceList
      cdkListbox
      cdkListboxOrientation="vertical"
      class="w-full"
      [cdkListboxValue]="[serviceControl.value]"
      (cdkListboxValueChange)="serviceSelected($event.value[0])"
    >
      <li
        class="focus:bg-gray-50 hover:bg-gray-50 p-2 cursor-pointer"
        [cdkOption]="service"
        (click)="serviceSelected(service)"
        *ngFor="let service of services; let last = last"
        [ngClass]="{
          'border-b': !last,

          'ring ring-inset ring-primary': service === serviceControl.value
        }"
      >
        <ng-container
          *ngTemplateOutlet="
            serviceTemplate;
            context: {
              $implicit: {
                price: service.prices.length>1?null:service.prices[0],
                service: service,
                prices: endLocation ? service.prices:[],
              }
            }
          "
        ></ng-container>
      </li>
      <li class="hidden" [cdkOptionDisabled]="true" [cdkOption]="null"></li>
    </ul>
  </ng-template>

  <ng-template #showSelected>
    <div
      class="px-3"
      *ngIf="serviceControl.value"
      (click)="serviceControl.enabled ? toggleList() : null"
      [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
    >
      <ng-container
        *ngTemplateOutlet="
          serviceTemplate;
          context: {
            $implicit: {
              price: pricingControl.value,
              service: serviceControl.value,
              prices: endLocation ? serviceControl.value?.prices : []
            }
          }
        "
      ></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #serviceTemplate let-data>
  <div
    class="grid grid-cols-4 gap-2 w-full py-2"
    *ngIf="data?.service?.displayName"
  >
    <div
      *ngIf="!data?.imageError"
      class="col-span-1 flex items-center justify-center"
    >
      <img
        class="w-full h-10 object-contain"
        [src]="data?.service.imageUrl"
        (error)="data.imageError = true"
      />
    </div>

    <div
      [ngClass]="{
        'col-span-4': data?.imageError,
        'col-span-3': !data?.imageError
      }"
      class="flex flex-col"
    >
      <div class="grid grid-cols-3 justify-between">
        <div class="col-span-2">{{ data?.service.displayName }}</div>
        <div
          class="col-span-1 text-right"
          *ngIf="data?.price?.displayPrice !== '$0'"
        >
          {{ data?.price?.displayPrice }}
        </div>
      </div>
      <div class="grid grid-cols-4 justify-between text-xs text-gray-500">
        <div
          [ngClass]="{
            'col-span-4': !data?.price?.displayPrice,
            'col-span-3': data?.price?.displayPrice
          }"
        >
          {{ data?.service.description }}
        </div>

        <div
          [ngClass]="{
            'col-span-0': !data?.price?.displayPrice,
            'col-span-1': !data?.price?.displayPrice,
            hidden:
              loading ||
              !endLocation ||
              (!hidePricingOptions &&
                (data.prices?.length !== 1 ||
                  (showList && data.prices?.length > 1))),
            'underline cursor-pointer':
              hidePricingOptions && data.prices?.length > 1
          }"
          class="text-right"
          (click)="
            $event.stopPropagation();
            hidePricingOptions && data.prices?.length > 1
              ? (hidePricingOptions = false)
              : null
          "
        >
          {{ data?.price?.pricingMethodType | titleCaseAndClean }}
        </div>
        <div class="col-span-4 mt-1" *ngIf="!endLocation">
          Destination required for fare estimate
        </div>
      </div>
    </div>

    <ng-container *ngIf="data.prices.length > 1 && !hidePricingOptions">
      <div *ngIf="loading; else pricesLoaded" class="col-span-4 text-sm my-2">
        Fetching Prices ...
      </div>
      <ng-template #pricesLoaded>
        <ng-container *ngFor="let price of data.prices; let i = index">
          <div
            [ngClass]="{
              'ring-1 ring-inset ring-gray-500':
                pricingControl.value?.pricingMethodId === price.pricingMethodId,

              'cursor-pointer': serviceControl?.enabled
            }"
            (click)="
              $event.stopPropagation();
              serviceControl?.enabled
                ? setServiceAndPrice(data.service, price)
                : null
            "
            class="flex flex-col items-center justify-center p-2 gap-1 rounded-md bg-default hover:ring-1 hover:ring-gray-500"
          >
            <div class="text-sm font-secondary">
              {{ price.pricingMethodType | titleCaseAndClean }}
            </div>

            <div
              class="whitespace-normal text-sm"
              *ngIf="price.pricingMethodType !== 'FLAT'; else flatTariff"
            >
              {{ price.displayPrice ? price.displayPrice : '?' }}
            </div>
            <ng-template #flatTariff>
              <div class="whitespace-normal text-sm">
                {{
                  totalTariffControl.value
                    ? (totalTariffControl.value | currency)
                    : 'Specify'
                }}
              </div></ng-template
            >
          </div>
        </ng-container>
        <div class="w-full flex justify-center items-center">
          <mat-icon
            class="icon-size-5"
            matTooltip="Fare Details"
            (click)="$event.stopPropagation(); openExternalPricingLink()"
            >info</mat-icon
          >
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div
    class="border-b-0 mt-4 flex flex-col w-full"
    *ngIf="
      pricingControl?.value?.pricingMethodType === 'FLAT' &&
      !loading &&
      !showList
    "
    (click)="$event.stopPropagation()"
  >
    <mat-form-field
      class="w-full"
      appearance="fill"
      matTooltip="flatFareSplit?'Primary Fare Amount':'Flat Fare Amount"
    >
      <mat-label class="text-sm font-light">Total Tariff</mat-label>
      <mat-icon class="icon-size-5" matPrefix
        ><span class="material-symbols-outlined">
          monetization_on
        </span></mat-icon
      >
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        currencyMask
        [options]="{ align: 'left', allowNegative: false }"
        placeholder="Enter Flat Fare Amount"
        [formControl]="totalTariffControl"
      />

      <mat-error>An total tariff amount is required for flat fare</mat-error>
      <span
        matSuffix
        *ngIf="!flatFareSplit"
        class="cursor-pointer underline text-sm text-primary"
        [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
        (click)="serviceControl?.enabled ? (flatFareSplit = true) : null"
        >Split</span
      >
      <span
        matSuffix
        *ngIf="flatFareSplit"
        class="cursor-pointer underline text-sm text-primary"
        (click)="flatFareSplit = false; primaryTariffControl.setValue(null)"
        >No Split</span
      >
    </mat-form-field>

    <mat-form-field
      *ngIf="flatFareSplit"
      class="w-full mb-4"
      matTooltip="Primary Tariff Payment Amount"
    >
      <mat-label class="text-sm font-light"
        >Primary Tariff Payment Amount</mat-label
      >
      <mat-icon class="icon-size-5" matPrefix>payments</mat-icon>

      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        currencyMask
        [options]="{ align: 'left', allowNegative: false }"
        placeholder="Enter Primary Payment Amount"
        [formControl]="primaryTariffControl"
      />
      <mat-error *ngIf="primaryTariffControl.getError('required')"
        >A primary payment amount is required for split fares</mat-error
      >
      <mat-error *ngIf="primaryTariffControl.getError('max')"
        >A primary payment amount must be less than the total for a split
        fare</mat-error
      >
      <mat-hint *ngIf="secondaryTariffPaymentAmount"
        >Secondary Tariff Payment =
        {{
          secondaryTariffPaymentAmount | number : '1.2-2' | currency
        }}</mat-hint
      >
    </mat-form-field>
  </div>
</ng-template>
