import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionBannerComponent } from './version-banner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [VersionBannerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [VersionBannerComponent],
})
export class VersionBannerModule {}
