import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'fleet-version-banner',
  templateUrl: './version-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionBannerComponent implements OnInit {
  envName: any;
  product: string;
  version: string;
  hide = false;
  constructor(
    @Inject('env') env: any,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.envName = env.envName;
    this.product = env.fleetProduct;
    this.version = env.version;
  }

  ngOnInit(): void {}

  hideBanner() {
    this.hide = true;
    this.changeDetectorRef.markForCheck();
  }
}
