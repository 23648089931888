import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { InjectionToken } from '@angular/core';
import { merge } from 'lodash-es';

import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';
import { generateStylesByColorHex } from './brand-utilities/css-theme-generator';
import * as WebFont from 'webfontloader';
import {
  genericAssignedVehicle,
  genericAvailableVehicle,
  genericBusyVehicle,
} from './brand_assets/generic-vehicle-markers';
import {
  swanAssignedVehicle,
  swanAvailableVehicle,
  swanBusyVehicle,
} from './brand_assets/swan-vehicle-markers';
import { LocaleService } from '@fleet/locale';
import { NetworkGroupApiService } from '@fleet/api';
import { NetworkGroupModel } from '@fleet/model';
import {
  flyWheelBrand,
  genericBrand,
  swanBrand,
} from './brand_assets/brand-data';
import { fleetBrand } from './brand_assets/fleet-brand';
import { NetworkGroupService } from '@fleet/network-group';
import { BrandConfig } from './model/brand-config-model';

export const PRODUCT_CONFIG = new InjectionToken<any>('PRODUCT_CONFIG');

@Injectable({
  providedIn: 'root',
})
export class ProductConfigurationService {
  productConfiguration: BehaviorSubject<any> = new BehaviorSubject(null);
  nonAuthorisedHttp: HttpClient;
  private _config: BehaviorSubject<any>;
  host: string;
  headers: any;
  fleetProduct: string;
  originalConfig: BrandConfig;

  networkConfig: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    @Inject('env') env: any,
    @Inject(PRODUCT_CONFIG) config: any,
    private onScreenNotificationService: OnscreenNotificationService,
    @Inject(DOCUMENT) private readonly document: Document,
    private titleService: Title,
    private localeService: LocaleService,
    private networkGroupApi: NetworkGroupApiService
  ) {
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.productConfiguration;
    this.fleetProduct = env.fleetProduct;
    this._config = new BehaviorSubject(config);
    this.headers = new HttpHeaders({
      'fleet-channel': 'WEB',
      'fleet-product': env.fleetProduct,
    });
  }

  get networkConfig$() {
    return this.networkConfig.asObservable();
  }

  get networkConfigValue() {
    return this.networkConfig.value;
  }

  getProductConfiguration(): Promise<any> {
    return this.nonAuthorisedHttp
      .get(this.host, { headers: this.headers })
      .pipe(
        tap((resp: any) => {
          this.networkConfig.next({
            networkId: resp.data.networkId,
            networkGroupId: resp.data.networkGroupId,
          });
          const hasLocalConfig = localStorage.getItem('brandConfig');
          if (hasLocalConfig) {
            this.originalConfig = JSON.parse(hasLocalConfig);
            this.loadBrandConfig(this.originalConfig);
          } else {
            const brand = resp.data;

            //HACK UNTIL SERVER IS SETUP
            if (brand.displayName === 'Swan Taxis') {
              // brand = swanBrand;
              this.originalConfig = swanBrand;
            } else if (
              brand.displayName === 'Flywheel' ||
              brand.displayName === 'Nashville Demo'
            ) {
              this.originalConfig = flyWheelBrand;
            } else if (brand.displayName === 'FLEET Demo') {
              this.originalConfig = fleetBrand;
            } else {
              //ingogo for now
              this.originalConfig = fleetBrand;
            }
            //HACK FOR GENERIC
            // brand = genericBrand;

            this.loadBrandConfig(this.originalConfig);
          }
        }),
        catchError((error) => {
          console.log('Product config error:' + error);
          this.onScreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Brand Initialisation Failed!',
            subTitle: 'Default settings applied',
          } as OnScreenNotification);
          this.originalConfig = fleetBrand;
          this.loadBrandConfig(this.originalConfig);
          return of(true);
        })
      )
      .toPromise();
  }

  loadBrandConfig(config: BrandConfig) {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    const pageTitle = config.productNames.find(
      (s: any) => s.fleetProduct === this.fleetProduct
    ).name;

    this.titleService.setTitle(pageTitle);
    favIcon.href = config.favIcon;

    if (config.locale) {
      this.localeService.setLocale(config.locale);
      // this.localeService.setLocale('en-US');
    }
    if (config.currencyCode) {
      this.localeService.setCurrency(config.currencyCode);
      // this.localeService.setCurrency('USD');
    }

    this.setCssVariables(config.theme, this.document);
    if (config.font) {
      this.loadFont(config.font);
    }
    this.config = config;
  }

  /**
   * Constructor
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: any) {
    // Merge the new config over to the current config
    const config = merge({}, this._config.getValue(), value);

    // Execute the observable
    this._config.next(config);
  }

  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  getLayout() {
    return this._config.value.layout as string;
  }

  publish() {
    localStorage.setItem('brandConfig', JSON.stringify(this._config.value));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the config to the default
   */
  reset(): void {
    // Set the config
    this._config.next(this.config);
  }

  setCssVariables(theme: any, document: Document) {
    let styles: any[] = [];
    styles = [...styles, ...generateStylesByColorHex(theme.primary, 'primary')];

    if (theme.accent) {
      styles = [...styles, ...generateStylesByColorHex(theme.accent, 'accent')];
    }
    if (theme.warn) {
      styles = [...styles, ...generateStylesByColorHex(theme.warn, 'warn')];
    }

    styles = [
      ...styles,
      ...generateStylesByColorHex(
        theme.controls ? theme.controls : theme.primary,
        'controls'
      ),
    ];

    styles.forEach((style: any) => {
      document.body.style.setProperty(style.var, style.value);
    });
  }

  loadFont(font: string) {
    WebFont.load({
      google: {
        families: [font],
      },
    });
    document.documentElement.style.setProperty('--app-font', font);
  }
}
