import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateBannerComponent } from './update-banner.component';

@NgModule({
  declarations: [UpdateBannerComponent],
  imports: [CommonModule],
  exports: [UpdateBannerComponent],
})
export class UpdateBannerModule {}
