import { FuseNavigationItem } from '@fleet/model';

export const networkMenuChildren = [
  {
    id: 'job',
    title: 'Jobs',
    icon: 'place',
    link: '/job/search',
    type: 'collapsable',
    securityFunctions: ['JOB:read'],

    children: [
      {
        id: 'job.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/job/search',
        exactMatch: true,
        securityFunctions: ['JOB_SEARCH:read'],
      },
      {
        id: 'job.active',
        title: 'Active Jobs',
        type: 'basic',
        icon: 'stream',
        link: '/job/active',
        securityFunctions: ['JOB_ACTIVITY:read'],
      },
      {
        id: 'job.new',
        title: 'New Job',
        type: 'basic',
        icon: 'add',
        link: '/job/new',
        securityFunctions: ['JOB_DETAIL:create'],
      },
      {
        id: 'job.edit',
        title: 'Edit Job',
        type: 'basic',
        hidden: () => hideItem(),
        link: '/job',
        securityFunctions: ['JOB_DETAIL:update'],
        classes: { wrapper: 'hidden' },
      },
    ],
  },
  {
    id: 'driver',
    title: 'Drivers',
    icon: 'person',
    link: '/driver',
    type: 'collapsable',
    securityFunctions: ['DRIVER:read'],

    children: [
      {
        id: 'driver.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/driver/search',
        exactMatch: true,
        securityFunctions: ['DRIVER_SEARCH:read'],
      },
      {
        title: 'Reviews',
        type: 'basic',
        icon: 'grading',
        link: '/driver/reviews',
        exactMatch: true,
        securityFunctions: ['DRIVER_REVIEW:read'],
      },
    ],
  },

  {
    id: 'operator',
    title: 'Operators',
    icon: 'supervised_user_circle',
    link: '/operator/search',
    securityFunctions: ['OPERATOR:read'],

    type: 'collapsable',
    children: [
      {
        id: 'operator.search',
        title: 'Search',
        link: '/operator/search',
        icon: 'search',
        type: 'basic',
        securityFunctions: ['OPERATOR_SEARCH:read'],
      },
      {
        id: 'operator.outstanding.invoice',
        title: 'Outstanding Invoices',
        link: '/operator/outstanding-invoices',
        icon: 'request_quote',
        type: 'basic',
        securityFunctions: ['OPERATOR_OUTSTANDING_INVOICES:read'],
      },
    ],
  },
  {
    id: 'organisation',
    title: 'Organisations',
    icon: 'business',
    link: '/organisation/search',
    securityFunctions: ['ORGANISATION:read'],
    type: 'collapsable',
    children: [
      {
        id: 'organisation.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/organisation/search',
        exactMatch: true,
        securityFunctions: ['ORGANISATION_SEARCH:read'],
      },
    ],
  },
  {
    id: 'traveller',
    title: 'Travellers',
    icon: 'hail',
    link: '/traveller',
    type: 'collapsable',
    securityFunctions: ['TRAVELLER:read'],

    exactMatch: false,
    children: [
      {
        id: 'traveller.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/traveller/search',
        exactMatch: true,
        securityFunctions: ['TRAVELLER_SEARCH:read'],
      },
    ],
  },
  {
    id: 'vehicle',
    title: 'Vehicles',
    icon: 'local_taxi',
    securityFunctions: ['VEHICLE:read'],

    link: '/vehicle',
    type: 'collapsable',
    exactMatch: false,
    children: [
      {
        id: 'vehicle.search',
        title: 'Search',
        type: 'basic',
        icon: 'search',
        link: '/vehicle/search',
        exactMatch: true,
        securityFunctions: ['VEHICLE_SEARCH:read'],
      },
      {
        id: 'vehicle.fleet',
        title: 'Active Vehicles',
        type: 'basic',
        icon: 'radar',
        link: '/vehicle/fleet',
        exactMatch: true,
        securityFunctions: ['VEHICLE_ACTIVE_SEARCH:read'],
      },
      {
        id: 'position',
        title: 'Position Search',
        type: 'basic',
        icon: 'share_location',
        link: '/position',
        securityFunctions: ['POSITION_SEARCH:read'],
      },
    ],
  },
  {
    title: 'Hardware',
    type: 'collapsable',
    icon: 'devices',
    id: 'hardware',
    link: '/hardware',
    securityFunctions: ['HARDWARE:read'],

    children: [
      {
        title: 'Assets',
        type: 'basic',
        icon: 'heroicons_outline:device-mobile',
        link: '/hardware/asset',
        id: 'hardware.asset',
        securityFunctions: ['HARDWARE_ASSET_SEARCH:read'],
      },
      {
        title: 'Orders',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/hardware/order',
        id: 'hardware.order',
        securityFunctions: ['HARDWARE_ORDER_SEARCH:read'],
      },
      {
        title: 'Shipments',
        type: 'basic',
        icon: 'heroicons_outline:truck',
        link: '/hardware/shipment',
        id: 'hardware.shipment',
        securityFunctions: ['HARDWARE_SHIPMENT_SEARCH:read'],
      },
      {
        title: 'Return Authorisations',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/hardware/return-authorisation',
        id: 'hardware.returns',
        securityFunctions: ['HARDWARE_RETURN_AUTHORISATION_SEARCH:read'],
      },
    ],
  },

  {
    id: 'finance',
    title: 'Finance',
    icon: 'account_balance',

    type: 'collapsable',
    securityFunctions: ['FINANCE:read'],

    children: [
      {
        title: 'Settlements',
        type: 'collapsable',
        icon: 'heroicons_outline:currency-dollar',
        id: 'settlement',
        securityFunctions: ['FINANCE_SETTLEMENTS:read'],
        children: [
          {
            title: 'Search',
            type: 'basic',
            icon: 'heroicons_outline:search',
            link: '/settlement/search',
            id: 'settlement.search',
            exactMatch: true,
            securityFunctions: ['FINANCE_SETTLEMENT_SEARCH:read'],
          },
          {
            title: 'Batches',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-list',
            link: '/settlement/batches',
            id: 'settlement.batches',
            exactMatch: true,
            securityFunctions: ['FINANCE_SETTLEMENT_BATCH:read'],
          },
        ],
      },

      {
        title: 'Direct Debits',
        type: 'collapsable',
        icon: 'heroicons_outline:cash',
        id: 'direct-debit',
        securityFunctions: ['FINANCE_DIRECT_DEBITS:read'],
        children: [
          {
            title: 'Search',
            type: 'basic',
            icon: 'heroicons_outline:search',
            link: '/direct-debit/search',
            id: 'direct-debit.search',
            exactMatch: true,
            securityFunctions: ['FINANCE_DIRECT_DEBIT_SEARCH:read'],
          },
          {
            title: 'Batches',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-list',
            link: '/direct-debit/batches',
            id: 'direct-debit.batches',
            exactMatch: true,
            securityFunctions: ['FINANCE_DIRECT_DEBIT_BATCH:read'],
          },
        ],
      },
      {
        title: 'Cabcharge Import',
        type: 'basic',
        icon: 'heroicons_outline:arrow-up-on-square-stack',
        link: 'cabcharge',
        id: 'import.cabcharge',
        securityFunctions: ['CABCHARGE_IMPORT_ADMIN:read'],
      },
    ],
  },

  {
    id: 'notifications',
    title: 'Notifications',
    icon: 'notifications',
    link: '/notification/search',
    exactMatch: true,

    type: 'basic',
    securityFunctions: ['NOTIFICATIONS:read'],
  },
  {
    title: 'Calls',
    type: 'basic',
    icon: 'heroicons_outline:phone',
    link: '/calls',

    securityFunctions: ['CALL:read'],
  },
  {
    id: 'tools',

    title: 'Tools',
    type: 'collapsable',
    icon: 'hardware',
    link: '/geo-tools',
    fleetOnly: true,
    children: [
      // {
      //   title: 'Positions',
      //   type: 'basic',
      //   icon: 'pin_drop',
      //   link: '/geo-tools/position',
      // },
      {
        title: 'Isochrones',
        type: 'basic',
        icon: 'radar',
        link: '/geo-tools/isochrone',
        fleetOnly: true,
      },
      // {
      //   title: 'Boundaries',
      //   type: 'basic',
      //   icon: 'map',
      //   link: '/geo-tools/boundary',
      // },
      {
        title: 'Tolls',
        type: 'basic',
        icon: 'toll',
        link: '/geo-tools/toll',
        fleetOnly: true,
      },
      {
        title: 'Polyline',
        type: 'basic',
        icon: 'bolt',
        link: '/geo-tools/polyline',
        fleetOnly: true,
      },
    ],
  },
  {
    title: 'Settings',
    type: 'collapsable',
    icon: 'heroicons_outline:cog',
    id: 'group.settings',
    // link: '/settings',
    securityFunctions: ['NETWORK:read'],

    children: [
      {
        title: 'Security',
        type: 'basic',
        icon: 'heroicons_outline:shield-check',
        link: '/settings/security',
        id: 'group.security',
        securityFunctions: ['NETWORK_SECURITY:read'],
      },
      {
        title: 'Dispatch',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-list',
        link: '/settings/area',
        id: 'group.dispatch',
        securityFunctions: ['NETWORK_DISPATCH:read'],
      },
      {
        title: 'Management',
        type: 'basic',
        icon: 'heroicons_outline:clipboard',
        link: '/settings/management',
        id: 'group.management',
        securityFunctions: ['NETWORK_MANAGEMENT:read'],
      },
      {
        title: 'Promotions',
        type: 'basic',
        icon: 'heroicons_outline:sparkles',
        link: '/settings/promotion',
        id: 'group.promotion',
        securityFunctions: ['PROMOTIONS:read'],
      },
      {
        title: 'Brand',
        type: 'basic',
        icon: 'style',
        link: '/settings/brand',
        id: 'brand',
      },
    ],
  },

  {
    title: 'Sign Out',
    type: 'basic',
    icon: 'heroicons_outline:logout',
    link: '/auth/logout',
    id: 'signout',
  },
];

export const networkNavigation = [
  {
    type: 'group',
    children: networkMenuChildren,
  },
];

export const networkNavigationNoGroups = [
  {
    type: 'group',
    children: networkMenuChildren.filter((s) => s.id !== 'network-group'),
  },
];

export const hideItem = () => {
  return true;
};
