import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeRangeControlComponent } from './date-time-range-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateWithTimeControlModule } from '../date-with-time-control';

@NgModule({
  declarations: [DateTimeRangeControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,

    DateWithTimeControlModule,
  ],
  exports: [DateTimeRangeControlComponent],
})
export class DateTimeRangeControlModule {}
