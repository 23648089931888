<form
  *ngIf="dateTimeForm"
  [formGroup]="dateTimeForm"
  [class]="controlCustomClass ? controlCustomClass : 'flex flex-col'"
>
  <mat-form-field class="w-full" *ngIf="showNowControl">
    <mat-label [ngClass]="labelClass" *ngIf="dateLabel">{{
      dateLabel
    }}</mat-label>
    <mat-select
      [formControl]="nowControl"
      class="w-full"
      [placeholder]="datePlaceholder"
    >
      <mat-option value="NOW"> Now </mat-option>
      <mat-option value="SCHEDULED"> Scheduled </mat-option>
    </mat-select>
  </mat-form-field>
  <div
    class="flex flex-row gap-2 items-start w-full"
    *ngIf="
      !showNowControl || (showNowControl && nowControl.value === 'SCHEDULED')
    "
  >
    <mat-form-field class="flex-grow" [subscriptSizing]="'dynamic'">
      <mat-label [ngClass]="labelClass" *ngIf="dateLabel && !showNowControl">{{
        dateLabel
      }}</mat-label>
      <mat-icon class="icon-size-5" *ngIf="datePrefix" matPrefix>{{
        datePrefix
      }}</mat-icon>
      <input
        [min]="minDate"
        [max]="maxDate"
        [required]="required"
        [matDatepicker]="date"
        [placeholder]="datePlaceholder"
        [errorStateMatcher]="dateErrorState"
        matInput
        formControlName="date"
      />
      <mat-datepicker #date></mat-datepicker>
      <mat-error *ngIf="dateTimeForm?.get('date')?.errors?.required"
        >A valid date in the format dd/mm/yyyy is required
      </mat-error>
      <mat-error
        *ngIf="
          !dateTimeForm?.get('date')?.errors?.required &&
          dateTimeForm?.hasError('dateNotFuture')
        "
      >
        Please ensure that this is a future date
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="date">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
    </mat-form-field>

    <mat-form-field class="max-w-40" [subscriptSizing]="'dynamic'">
      <mat-icon class="icon-size-5" *ngIf="timePrefix" matPrefix>{{
        timePrefix
      }}</mat-icon>
      <mat-label [ngClass]="labelClass" *ngIf="timeLabel && !showNowControl">{{
        timeLabel
      }}</mat-label>
      <input
        [placeholder]="timePlaceholder"
        matInput
        formControlName="time"
        type="time"
        [required]="required"
        [errorStateMatcher]="timeErrorState"
      />
      <mat-error *ngIf="dateTimeForm?.get('time')?.errors?.required"
        >Please ensure you have a valid time & please specify am/pm</mat-error
      >
      <mat-error
        *ngIf="
          dateTimeForm?.hasError('timeNotFuture') &&
          !dateTimeForm?.get('time')?.errors?.required
        "
      >
        Please ensure that this is a future time
      </mat-error>
    </mat-form-field>
  </div>
</form>

<!--
req?{{ dateTimeForm?.get('date')?.errors?.required }} prist?{{
  dateTimeForm?.get('date')?.pristine
}}
touch?{{ dateTimeForm?.get('date')?.touched }} dirty{{
  dateTimeForm?.get('date')?.dirty
}}
-------------------- req?{{
  dateTimeForm?.get('time')?.errors?.required
}}
prist?{{ dateTimeForm?.get('time')?.pristine }} touch?{{
  dateTimeForm?.get('time')?.touched
}}
dirty{{ dateTimeForm?.get('time')?.dirty }} -->
