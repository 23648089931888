<ng-container *ngIf="issues.length > 0; else noIssues">
  <ng-container *ngIf="!loading; else showLoading">
    <fuse-alert
      *ngFor="let alert of issues | alertsFromIssues"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
    >
      {{ alert.message }}
    </fuse-alert>
  </ng-container>
</ng-container>

<ng-template #noIssues>
  <div class="flex flex-col px-3 py-4 gap-4">
    <ng-container *ngIf="!loading; else showLoading">
      <div
        class="flex items-center space-x-3 cursor-pointer"
        *ngIf="vehicle"
        (click)="navigateToVehicle()"
      >
        <img
          *ngIf="vehicle?.vehiclePhotoFront"
          class="w-8 h-8 rounded-full object-cover"
          [src]="vehicle.vehiclePhotoFront.url"
        />
        <div>
          <h3 class="text-gray-900 font-medium truncate">
            {{ vehicle.displayName }}
          </h3>
          <p class="mt-1 text-gray-500 truncate">
            {{ vehicle.plate.plateNumber }}
          </p>
        </div>
        <div class="flex flex-grow justify-end items-center">
          <mat-icon class="icon-size-5"> people </mat-icon>
          <div class="ml-2">{{ vehicle.travellerCapacity }}</div>
        </div>
      </div>

      <div class="flex flex-row gap-4">
        <div class="flex items-center" matTooltip="Speed">
          <mat-icon class="icon-size-5 mr-1">speed</mat-icon
          ><span>
            {{
              positionDetail?.position.speed
                ? (positionDetail.position.speed | number : '1.0-0')
                : 'unknown'
            }}</span
          >
        </div>
        <div class="flex items-center" matTooltip="Direction">
          <mat-icon class="icon-size-5 mr-1">explore</mat-icon
          ><span>
            {{ positionDetail?.position.heading | number : '1.0-0' }}</span
          >
        </div>
      </div>

      <div
        class="flex items-center space-x-3 cursor-pointer"
        *ngIf="driver"
        (click)="navigateToDriver()"
      >
        <img
          *ngIf="driver?.driverPhoto"
          class="w-8 h-8 rounded-full object-cover"
          [src]="driver.driverPhoto.url"
        />
        <div>
          <h3 class="text-gray-900 text-md font-medium truncate">
            {{
              driver.firstName === driver.preferredName
                ? driver.firstName + driver.lastName
                : driver.preferredName +
                  ' ' +
                  driver.firstName +
                  ' ' +
                  driver.lastName
            }}
          </h3>
          <p class="mt-1 text-gray-500 text-md truncate">
            {{ driver.taxiAuthorityNumber }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #showLoading>
  <mat-progress-spinner diameter="24"></mat-progress-spinner>
</ng-template>
