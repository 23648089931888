import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetFlexLayoutComponent } from './fleet-flex-layout.component';
import { FuseFullscreenModule } from '@fleet/fuse/lib/components/fullscreen';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fleet/fuse';
import { UpdateBannerModule, VersionBannerModule } from '@fleet/version';

@NgModule({ declarations: [FleetFlexLayoutComponent],
    exports: [FleetFlexLayoutComponent], imports: [CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        UpdateBannerModule,
        VersionBannerModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class FleetFlexLayoutModule {}
