<div
  class="absolute inset-0 flex flex-col min-w-0"
  [ngClass]="customClass ? customClass : ''"
>
  <mat-drawer-container
    class="flex flex-auto w-full h-full dark:bg-transparent"
    (backdropClick)="backdropClicked()"
  >
    <mat-drawer
      #matDrawer
      mode="over"
      position="end"
      class="w-full"
      [ngClass]="drawerWidth ? drawerWidth : 'max-w-140'"
    >
      <mat-drawer-content #matDrawerContent>
        <ng-content select="[slot=detail-drawer]"></ng-content>
      </mat-drawer-content>
    </mat-drawer>
    <mat-drawer-content
      #mainContent
      class="flex flex-col w-full flex-auto"
      id="container-content"
      [ngClass]="requiresPadding ? 'sm:mx-4 md:mx-6' : ''"
    >
      <div class="flex flex-col flex-auto dark:bg-transparent w-full pt-9 px-6">
        <!-- header -->
        <div class="flex flex-col pb-8">
          <!-- progress bar -->
          <!-- <div class="absolute bottom-0">
            <ng-content select="[slot=progress-bar]"></ng-content>
          </div> -->
          <!-- title && expand button -->

          <fleet-entity-header-with-breadcrumbs *ngIf="showEntityHeader">
          </fleet-entity-header-with-breadcrumbs>
          <!-- <ng-template #entityHeader>
            <fleet-driver-header *ngIf="entity && entity.driverId" class="w-full" [driver]="entity">
            </fleet-driver-header>

          </ng-template> -->

          <div class="flex flex-wrap justify-between items-center gap-4">
            <div
              class="text-2xl font-bold tracking-tight leading-none ml-4 md:ml-0"
            >
              {{ title }}
            </div>
            <div class="flex flex-wrap-reverse">
              <div class="flex flex-wrap items-center sm:mt-0 sm:ml-4 gap-4">
                <ng-content select="[slot=search-chips]"></ng-content>
                <div *ngIf="showFilterButton">
                  <button
                    class="min-w-10 min-h-7 h-7 px-2 leading-6"
                    mat-stroked-button
                    (click)="searchExpanded = !searchExpanded"
                  >
                    <mat-icon
                      class="icon-size-5"
                      [svgIcon]="
                        searchExpanded
                          ? 'heroicons_solid:chevron-up'
                          : 'heroicons_solid:chevron-down'
                      "
                    ></mat-icon>
                    <span class="ml-2 mr-1">Filter</span>
                  </button>
                </div>

                <ng-content select="[slot=actions]"></ng-content>
              </div>
            </div>
          </div>
        </div>

        <!-- expanded Search Panel -->
        <ng-container *ngIf="searchExpanded">
          <div class="flex flex-col mb-2 shadow-sm bg-card">
            <ng-content select="[slot=expanded-search]"></ng-content>
          </div>
        </ng-container>

        <ng-content select="[slot=first-row]"></ng-content>

        <div class="flex flex-col gap-2" [class.flex-auto]="flexAutoMainSlot">
          <ng-content *ngIf="!searchData" select="[slot=pre-results]">
          </ng-content>

          <div class="w-full">
            <ng-content select="[slot=first-row]"></ng-content>
            <ng-content select="[slot=error]"></ng-content>
            <ng-content select="[slot=pre-results]"></ng-content>
          </div>

          <ng-content
            *ngIf="
              searchData && searchData.pageData && searchData.data.length > 0
            "
            select="[slot=results]"
          >
          </ng-content>

          <mat-spinner
            *ngIf="searchData && searchData.searching"
            [diameter]="24"
            style="margin: 0 auto"
          ></mat-spinner>

          <fleet-no-results-label
            *ngIf="
              searchData &&
              searchData.pageData &&
              searchData.data.length === 0 &&
              !searchData.searching
            "
            [type]="resultEntityType"
          >
          </fleet-no-results-label>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
