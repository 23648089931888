<ng-container *ngIf="!manualEntry; else manualEntryForm">
  <mat-form-field
    [class]="formFieldClasses"
    [appearance]="appearance"
    [subscriptSizing]="'dynamic'"
    [ngClass]="'mb-4'"
  >
    <mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}</mat-label>
    <input
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      #input
      [placeholder]="placeHolder"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      (click)="$any($event.target).select()"
      #trigger="matAutocompleteTrigger"
    />

    <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
    <mat-icon
      [ngClass]="{ 'cursor-pointer': searchControl.enabled }"
      matSuffix
      class="icon-size-5"
      matTooltip="Use map"
      *ngIf="useMap && !searching"
      (click)="searchControl.enabled ? withMap() : null"
      >my_location</mat-icon
    >
    <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }} </mat-icon>
    <fleet-letter-icon
      *ngIf="letterPrefixIcon"
      [text]="letterPrefixIcon"
      iconSize="5"
      matPrefix
      cdkDragHandle
      class="mr-3 cursor-grab"
    >
    </fleet-letter-icon>

    <mat-icon
      matSuffix
      *ngIf="showRemove"
      (click)="remove.emit()"
      matTooltip="Remove"
      class="cursor-pointer icon-size-5"
      >close</mat-icon
    >

    <mat-error *ngIf="searchControl.getError('no-place-detail') && !searching"
      >Please ensure you enter a valid address</mat-error
    >
    <mat-error *ngIf="searchControl.getError('refine-needed') && !searching"
      >You need to refine your selection, click the address
    </mat-error>
    <mat-error *ngIf="searchControl.getError('required') && !searching"
      >Please search and select an address
    </mat-error>

    <mat-error
      *ngIf="
        searchControl?.errors?.placeInvalid &&
        !searchControl.getError('required') &&
        !searching
      "
      >{{ searchControl?.errors?.placeInvalid }}
    </mat-error>
  </mat-form-field>

  <fuse-alert
    *ngFor="let alert of issues | alertsFromIssues"
    class=""
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    [class]="'max-h-100'"
    (optionSelected)="optionSelected($event)"
    (closed)="autoCompleteClosed()"
  >
    <mat-option *ngIf="moreThanOnePlaceDetail" [disabled]="true"
      >Refine selection
    </mat-option>

    <ng-container
      *ngIf="
        (searchControl.value &&
          (searchControl.value.length === 0 ||
            searchControl.value === '#manual#')) ||
        !searchControl.value
      "
    >
      <mat-option
        *ngFor="let location of customLocations"
        [value]="location"
        class="multiline-mat-option"
      >
        <div>{{ location.name }}</div>
        <div class="text-sm text-secondary">{{ location.address }}</div>
      </mat-option>
    </ng-container>

    <mat-option
      *ngFor="let place of searchResults"
      [value]="place"
      class="multiline-mat-option"
    >
      <span
        *ngIf="!moreThanOnePlaceDetail; else moreThanOnePlaceDetail"
        [innerHTML]="place | locationListItem : searchControl.value"
      ></span>
      <ng-template #moreThanOnePlaceDetail>
        <div>{{ place.displayLine1 }}</div>
        <div class="text-sm text-secondary">{{ place.displayLine2 }}</div>
      </ng-template>
    </mat-option>
    <mat-option
      *ngIf="
        !suburbOnly &&
        !useMap &&
        !moreThanOnePlaceDetail &&
        searchControl.value?.length > 0 &&
        !searching
      "
      value="#manual#"
      >Cant find address?</mat-option
    >
    <mat-option
      *ngIf="useMap && searchControl.value?.length > 0 && !searching"
      value="#useMap#"
      class="text-sm"
      (click)="withMap()"
      >Use map</mat-option
    >

    <mat-option
      *ngIf="!searching && suburbOnlyClearable && suburbOnly"
      value="#change#"
      disabled
    >
      <div
        class="flex flex-row justify-between items-center text-md text-secondary"
      >
        <div class="">Searching suburbs only</div>
        <div
          class="cursor-pointer underline"
          [matTooltip]="'Switch to search addresses'"
          (click)="changeAutocompleteType(suburbOnly ? 'ALL' : 'SUBURB')"
        >
          Full addresses
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</ng-container>
<ng-template #manualEntryForm>
  <div class="mb-8">
    <div class="text-gray-500">
      {{ label ? label : placeHolder ? placeHolder : '' }}
    </div>

    <!-- *ngIf="mode === 'DEFAULT'; else formControl" -->
    <fleet-location-form
      [mode]="mode"
      [appearance]="appearance"
      (manualSubmit)="locationFromForm($event)"
      (localityLatLng)="useMapPickup.emit($event)"
    >
    </fleet-location-form>
    <!-- <ng-template #formControl>
      <fleet-location-form [formControl]="locationControl" [mode]="mode">
      </fleet-location-form>
    </ng-template> -->
  </div>
</ng-template>
