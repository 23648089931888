import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validator,
} from '@angular/forms';
import { NetworkGroupModel } from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { DateTime } from 'luxon';
import { DateWithTimeControlComponent } from '../date-with-time-control/date-with-time-control.component';

@Component({
  selector: 'fleet-date-time-range-control',
  templateUrl: './date-time-range-control.component.html',
  styleUrls: ['./date-time-range-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeRangeControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeRangeControlComponent),
      multi: true,
    },
  ],
})
export class DateTimeRangeControlComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, Validator
{
  @Input() formCustomClass: string;
  @Input() controlCustomClass: string;

  timeTypeControl = new UntypedFormControl();
  customTimeForm: UntypedFormGroup;
  @Input() label = 'Time Range';
  @Input() defaultTimeRange = 'TODAY';

  avaiableTimezoneControl = new UntypedFormControl();
  timezones: string[] = [];
  networkGroup: NetworkGroupModel;

  @Input() defaultStartTime: DateTime = DateTime.now().set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  @Input() defaultEndTime: DateTime = DateTime.now().set({
    hour: 23,
    minute: 59,
    second: 59,
  });

  @Input() effectiveToLabel: string = null;
  @Input() effectiveFromLabel: string = null;

  @ViewChild('startDate', { static: false })
  startDate: DateWithTimeControlComponent;

  @ViewChild('endDate', { static: false })
  endDate: DateWithTimeControlComponent;

  @Input() mode: 'LUXON' | 'LOCAL' | 'ISO' = 'LUXON';

  @Input() minDate: DateTime = null;
  @Input() showNowControl: boolean;

  _customOnly: boolean;
  @Input() set customOnly(value: boolean) {
    this._customOnly = value;
    if (value) {
      this.timeTypeControl.setValue('CUSTOM');
    }
  }

  get customOnly() {
    return this._customOnly;
  }

  @Input() hideTimezone = false;
  @Input() fromRequired = false;
  @Input() toRequired = false;

  @Input() inSearchForm = false;

  //ISO - format as an iso string;
  //luxon datetime object
  //local -to locale

  constructor(
    private fb: UntypedFormBuilder,
    private networkGroupService: NetworkGroupService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (!this.customOnly) {
      this.timeTypeControl.setValue(this.defaultTimeRange);
    }

    this.changeDetectorRef.markForCheck();
  }

  setTimeTypeControl(value: string) {
    this.timeTypeControl.setValue(value);
  }

  setIntialTimezone() {
    this.avaiableTimezoneControl.disable();
    this.timezones.push(Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.avaiableTimezoneControl.setValue(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    this.changeDetectorRef.markForCheck();
  }

  buildForm() {
    this.customTimeForm = this.fb.group({
      fromDate: [],
      toDate: [],
    });
  }
  ngOnInit(): void {
    this.buildForm();
    this.setIntialTimezone();

    this.avaiableTimezoneControl.valueChanges.subscribe((timezone) => {
      if (timezone) {
        this.sync();
      }
    });

    this.networkGroupService.networkGroup$.subscribe(
      (networkGroup: NetworkGroupModel) => {
        this.networkGroup = networkGroup;

        if (networkGroup && networkGroup.timezone) {
          this.avaiableTimezoneControl.enable();
          this.timezones.unshift(networkGroup.timezone);
          this.avaiableTimezoneControl.setValue(networkGroup.timezone);
          this.changeDetectorRef.markForCheck();
        }
      }
    );

    this.timeTypeControl.valueChanges.subscribe((type: string) => {
      let now = DateTime.now();

      let time = now;
      if (type !== 'CUSTOM') {
        switch (type) {
          case '3HRS':
            time = time.minus({ hours: 3 });
            break;
          case '12HRS':
            time = time.minus({ hours: 12 });
            break;
          case '24HRS':
            time = time.minus({ hours: 24 });
            break;
          case 'TODAY':
            time = time.startOf('day');
            now = now.endOf('day');
            break;
          case 'TOMORROW':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 1 }).endOf('day');

            break;

          case 'NOW':
            now = time.plus({ hours: 1 });
            time = DateTime.now().minus({ hours: 1 });
            break;

          case 'NEXT_HOUR':
            now = time.plus({ hours: 1 });
            time = DateTime.now();
            break;

          case 'NEXT_3HOURS':
            now = time.plus({ hours: 3 });
            time = DateTime.now();
            break;

          case 'NEXT_12HOURS':
            now = time.plus({ hours: 12 });
            time = DateTime.now();
            break;

          case '2DAYS':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 2 }).endOf('day');
            break;
          case 'WEEK':
            time = time.plus({ days: 1 }).startOf('day');
            now = now.plus({ days: 7 }).endOf('day');
            break;
          default:
            time = time.minus({ hours: 24 });
            break;
        }

        if (this.networkGroup && this.networkGroup.timezone) {
          //reset the selected timezone - in the event that it was changed when playing with custom times
          this.avaiableTimezoneControl.setValue(this.networkGroup.timezone);
        }

        this.onTouched();

        //reset the custom pickers
        if (this.startDate) {
          this.startDate.reset();
        }
        if (this.endDate) {
          this.endDate.reset();
        }

        if (this.mode === 'LUXON') {
          this.onChange({
            fromDate: time,
            toDate: now,
          });
        } else if (this.mode === 'LOCAL') {
          let from = time.toString().split(/([+-]\d{2}:\d{2})/);
          let to = now.toString().split(/([+-]\d{2}:\d{2})/);

          this.onChange({
            fromDate: from[0],
            toDate: to[0],
          });
        } else {
          this.onChange({
            fromDate: time,
            toDate: now,
          });
        }

        this.changeDetectorRef.markForCheck();
      }
    });

    this.customTimeForm.valueChanges.subscribe((val) => {
      if (val) {
        this.sync();
      }
    });
  }

  onChange: any = (locality: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: { fromDate: DateTime; toDate: DateTime }) {
    if (value) {
      setTimeout(() => {
        if (value.fromDate) {
          this.customTimeForm.get('fromDate').patchValue(value.fromDate);
          this.changeDetectorRef.markForCheck();
        }
        if (value.toDate) {
          this.customTimeForm.get('toDate').patchValue(value.toDate);
          this.changeDetectorRef.markForCheck();
        }
      }, 10);
    }

    this.changeDetectorRef.markForCheck();
  }

  sync() {
    let from = this.customTimeForm.get('fromDate').value as DateTime;
    let to = this.customTimeForm.get('toDate').value as DateTime;

    if (this.avaiableTimezoneControl.value) {
      if (from) {
        if (this.mode !== 'LOCAL') {
          from = from.setZone(this.avaiableTimezoneControl.value, {
            keepLocalTime: true,
          });
        }
      } else {
        let now = DateTime.now();

        from = now

          .setZone(this.avaiableTimezoneControl.value, {
            keepLocalTime: true,
          })
          .minus({ hours: 3 });
      }

      if (to) {
        if (this.mode !== 'LOCAL') {
          to = to.setZone(this.avaiableTimezoneControl.value, {
            keepLocalTime: true,
          });
        }
      } else {
        let now = DateTime.now();

        to = now.setZone(this.avaiableTimezoneControl.value, {
          keepLocalTime: true,
        });
      }
    }

    this.onTouched();
    this.changeDetectorRef.markForCheck();

    if (this.mode === 'LUXON') {
      this.onChange({
        fromDate: from,
        toDate: to,
      });
    } else if (this.mode === 'LOCAL') {
      let fromLocal = {} as any;
      let toLocal = {} as any;

      if (this.customTimeForm.value.fromDate) {
        const fromDateValue = this.customTimeForm
          .get('fromDate')
          .value.toString();
        const fromDateSplit = fromDateValue.split(/([+-]\d{2}:\d{2})/);
        fromLocal = {
          dateTime: fromDateSplit[0],
          timeZone: fromDateSplit[1] ? fromDateSplit[1] : '',
        };
      }

      if (this.customTimeForm.value.toDate) {
        const toDateValue = this.customTimeForm.get('toDate').value.toString();
        const toDateSplit = toDateValue.split(/([+-]\d{2}:\d{2})/);
        toLocal = {
          dateTime: toDateSplit[0],
          timeZone: toDateSplit[1] ? toDateSplit[1] : '',
        };
      }

      this.onChange({
        fromDate: fromLocal.dateTime,
        toDate: toLocal.dateTime,
      });
    } else {
      this.onChange({
        fromDate: from,
        toDate: to,
      });
    }
  }

  resetControl() {
    if (this.networkGroup && this.networkGroup.timezone) {
      this.avaiableTimezoneControl.setValue(this.networkGroup.timezone);
    }

    //could be hidden since it relies on the selector to make it exist.
    if (this.startDate) {
      this.startDate.reset();
    }
    if (this.endDate) {
      this.endDate.reset();
    }

    this.timeTypeControl.reset();
    this.changeDetectorRef.markForCheck();
  }

  resetStartDate() {
    this.startDate.reset();
    this.changeDetectorRef.markForCheck();
  }

  resetEndDate() {
    this.endDate.reset();
    this.changeDetectorRef.markForCheck();
  }

  validate(value: any) {
    if (
      (this.fromRequired && !this.customTimeForm.get('fromDate').value) ||
      (this.toRequired && !this.customTimeForm.get('toDate').value) ||
      (this.toRequired &&
        this.minDate > this.customTimeForm.get('toDate').value) ||
      this.minDate > this.customTimeForm.get('fromDate').value
    ) {
      return { required: true };
    } else {
      return null;
    }
  }
}
