<form [formGroup]="form" class="flex flex-col gap-3">
  <div
    class="flex gap-3"
    [ngClass]="{
      'flex-row': mode === 'horizontal',
      'flex-col': mode === 'verticle'
    }"
  >
    <mat-form-field class="w-full fuse-mat-dense">
      <mat-label *ngIf="mode === 'verticle'">Type</mat-label>

      <mat-select formControlName="type" placeholder="Type...">
        <mat-option
          *ngFor="let attribute of ATTRIBUTE_TYPES"
          [value]="attribute.value"
        >
          {{ attribute.name }}
        </mat-option>
      </mat-select>

      <mat-error>Type is required</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full fuse-mat-dense">
      <mat-label *ngIf="mode === 'verticle'">Display name</mat-label>

      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder="Display name"
        formControlName="displayName"
      />
      <mat-error>Display name is required</mat-error>
    </mat-form-field>

    <mat-form-field
      [subscriptSizing]="'dynamic'"
      class="w-full fuse-mat-dense mb-4"
      *ngIf="
        form.get('type').value !== 'DATE_TIME' &&
        form.get('type').value !== 'LOCAL_DATE'
      "
    >
      <mat-label *ngIf="mode === 'verticle'">Value</mat-label>

      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder=" Value"
        formControlName="value"
      />
      <mat-error *ngIf="!this.form.get('value').value"
        >Value is required</mat-error
      >
      <mat-error *ngIf="error && this.form.get('value').value">{{
        error
      }}</mat-error>
    </mat-form-field>
  </div>

  <fleet-date-with-time-control
    *ngIf="
      form.get('type').value === 'DATE_TIME' ||
      form.get('type').value === 'LOCAL_DATE'
    "
    [defaultTime]="defaultTime"
    #valueDate
    formControlName="value"
    [required]="true"
    [dateLabel]="mode === 'verticle' ? 'Date' : null"
    [timeLabel]="mode === 'verticle' ? 'Time' : null"
  >
  </fleet-date-with-time-control>
</form>
