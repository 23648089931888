import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ServiceApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  JobOrganisationModel,
  LocationModel,
  NetworkGroupModel,
  NetworkServicePriceModel,
  ServicePriceItemModel,
  ServicePriceModel,
} from '@fleet/model';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

import { CdkListbox } from '@angular/cdk/listbox';
import { defaultServicePrice } from './default-service-price';
import { hasRequiredValidator } from '@fleet/utilities';
//   id: 'Sydney/Australia',
//   offset: 36000,
//   dstOffset: 0,
// };

export interface ServiceWithPricingItem {
  service: ServicePriceModel;
  pricing: ServicePriceItemModel;
  totalTariff?: number;
  primaryTariffPayment?: number;
}

@Component({
  selector: 'fleet-service-pricing-select',
  templateUrl: './service-pricing-select.component.html',
  styleUrls: ['./service-pricing-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => ServicePricingSelectComponent),
    //   multi: true,
    // },
  ],
})
export class ServicePricingSelectComponent
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
  appointment: any;
  serviceControl = new UntypedFormControl();
  servicePricingControl = new UntypedFormControl();
  pricingControl = new UntypedFormControl();
  services: ServicePriceModel[] = []; // this.mocksData;
  servicePricing: ServicePriceItemModel[];
  servicePrices: ServiceWithPricingItem[];
  loading = false;
  issues: IssueModel[];
  networkGroup: NetworkGroupModel;
  @Input() networkGroupUrl: string;
  flatFareSplit = false;
  totalTariffControl = new UntypedFormControl(null, [
    Validators.required,
    Validators.min(0.01),
  ]);
  primaryTariffControl = new UntypedFormControl(null, [Validators.required]);
  secondaryTariffPaymentAmount: number;

  @Input() startLocation: LocationModel;

  endLocationLatLng: boolean;
  _endLocation: LocationModel;
  @Input() set endLocation(value: LocationModel) {
    this._endLocation = value;

    if (value && value.latitude) {
      this.endLocationLatLng = true;
    } else {
      this.endLocationLatLng = false;
    }
  }
  get endLocation() {
    return this._endLocation;
  }

  @Input() numberOfTravellers: number;

  @Input() startTimeWithOffset: DateTime;
  @Input() serviceLineId: string;
  @Input() waypoints: LocationModel[];
  @Input() networkGroupId: string;
  @Output() routeUpdated = new EventEmitter();
  previousParams: any;
  @Input() jobOrganisation: JobOrganisationModel;
  @Output() servicesChanged = new EventEmitter();
  @Output() clicked = new EventEmitter();
  @ViewChild('serviceList', { read: CdkListbox<ServicePriceModel> })
  serviceList: CdkListbox<ServicePriceModel>;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  showList = false;
  defaultServicePrice = defaultServicePrice;
  hostFocused = false;
  hostRef: ElementRef;
  fleetProduct: string;
  hidePricingOptions = false;
  updatingInternally = false;

  constructor(
    private serviceApiService: ServiceApiService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('env') env: any,
    private elRef: ElementRef,
    @Self() public ngControl: NgControl
  ) {
    this.hostRef = elRef;
    this.fleetProduct = env.fleetProduct;
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.serviceControl.setValidators(this.ngControl.control.validator);
    this.serviceControl.updateValueAndValidity();

    this.ngControl.control.statusChanges.subscribe((status: string) => {
      this.serviceControl.setValidators(this.ngControl.control.validator);

      if (hasRequiredValidator(this.ngControl.control)) {
        //chat gpt says to add the required validator manually here if there specifcally...

        const currentValidators = this.ngControl.control.validator;
        const newValidators = Array.isArray(currentValidators)
          ? [...currentValidators, Validators.required]
          : [currentValidators, Validators.required];

        this.serviceControl.setValidators(newValidators);
      }

      if (this.ngControl.touched) {
        this.serviceControl.markAsTouched();
        this.serviceControl.updateValueAndValidity({ emitEvent: false });
      }

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });

    this.totalTariffControl.valueChanges.subscribe({
      next: (totalTariff: any) => {
        this.checkSplitFare(this.flatFareSplit);
        this.onChange({
          service: this.serviceControl.value,
          pricing: this.pricingControl.value,
          totalTariff: totalTariff,
          primaryTariffPayment: this.flatFareSplit
            ? this.primaryTariffControl.value
            : null,
        });
        this.onTouched();
      },
    });

    this.primaryTariffControl.valueChanges.subscribe({
      next: (primaryTariff: any) => {
        this.checkSplitFare(this.flatFareSplit);

        if (
          this.totalTariffControl.value &&
          primaryTariff &&
          primaryTariff > 0
        ) {
          this.secondaryTariffPaymentAmount =
            this.totalTariffControl.value - primaryTariff;
        } else {
          this.secondaryTariffPaymentAmount = null;
        }
        this.onChange({
          service: this.serviceControl.value,
          pricing: this.pricingControl.value,
          totalTariff: this.totalTariffControl.value,
          primaryTariffPayment: primaryTariff,
        });
        this.onTouched();
      },
    });

    this.serviceControl.valueChanges.subscribe({
      next: (service: ServicePriceModel) => {
        if (service) {
          this.servicePricing = service.prices;
          this.routeUpdated.emit({
            route: service.route,
            routeEncodingVersion: service.routeEncodingVersion,
          });
          this.pricingControl.setValue(service.prices[0], { emitEvent: false });
          this.onChange({
            service: service,
            pricing: service.prices[0],
            totalTariff: this.totalTariffControl.value,
            primaryTariffPayment: this.primaryTariffControl.value,
          });
          this.onTouched();
        }
      },
    });

    this.pricingControl.valueChanges.subscribe({
      next: (pricing: ServicePriceItemModel) => {
        this.onChange({
          service: this.serviceControl.value,
          pricing: pricing,
          totalTariff: this.totalTariffControl.value,
          primaryTariffPayment: this.primaryTariffControl.value,
        });
        this.onTouched();
      },
    });
  }

  @HostListener('focus')
  onFocus() {
    this.hostFocused = true;
  }

  @HostListener('blur', ['$event.target'])
  onBlur() {
    this.hostFocused = false;
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.toggleList();
    }
    //handle side arrows on prices
    else if (event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
      if (this.pricingControl.value) {
        const service = this.serviceControl.value as ServicePriceModel;
        const price = this.pricingControl.value as ServicePriceItemModel;
        const index = service.prices.findIndex(
          (s) => s.pricingMethodId == price.pricingMethodId
        );
        if (service.prices.length > 1) {
          if (event.code === 'ArrowRight') {
            if (index < service.prices.length - 1) {
              this.pricingControl.setValue(service.prices[index + 1]);
            }
          } else if (event.code === 'ArrowLeft' && index !== 0) {
            this.pricingControl.setValue(service.prices[index - 1]);
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.startLocation && this.startTimeWithOffset && this.serviceLineId) {
      //@TODO service selector handle endLocation

      const params = this.prepareParamsForServiceSearch(
        this.startLocation,
        this.startTimeWithOffset,
        this.endLocation,
        this.waypoints,
        this.serviceLineId,
        this.numberOfTravellers,
        this.jobOrganisation
      );
      if (JSON.stringify(params) !== JSON.stringify(this.previousParams)) {
        if (!this.endLocation) {
          this.pricingControl.reset(null, { emitEvent: false });
        }

        this.previousParams = params;
        this.loading = true;
        this.issues = [];
        this.changeDetectorRef.markForCheck();
        this.routeUpdated.emit({ route: null, routeEncodingVersion: null });
        this.serviceApiService.searchServices(params).subscribe({
          next: (resp: ApiResponse<NetworkServicePriceModel[]>) => {
            this.previousParams = params;
            this.loading = false;

            this.servicePrices = [];
            this.services = resp.data[0].services ? resp.data[0].services : [];
            if (this.fleetProduct === 'BUSINESS') {
              //TEMP HACK TO REMOVE FLAT PRICING
              this.services = this.services.map(
                (service: ServicePriceModel) => {
                  return {
                    ...service,
                    prices: service.prices.filter(
                      (price: ServicePriceItemModel) =>
                        price.pricingMethodType !== 'FLAT'
                    ),
                  };
                }
              );
            }
            if (this.services.length === 0 && this.jobOrganisation) {
              //no results - lets open the list which should have our warning message saying no services
              this.showList = true;
              this.serviceControl.setValue(null);
              this.changeDetectorRef.markForCheck();
            }

            this.servicesChanged.emit([...this.services]);

            this.services.forEach((service: ServicePriceModel) => {
              service.prices.forEach((price: ServicePriceItemModel) => {
                this.servicePrices.push({
                  service: service,
                  pricing: price,
                });
              });
            });
            // this.changeDetectorRef.markForCheck();
            if (this.serviceControl.value) {
              const servicePrice = this.serviceControl.value;
              const servicePriceItem = this.pricingControl.value;
              const foundService = this.services.find(
                (s: ServicePriceModel) =>
                  s.networkServiceId === servicePrice.networkServiceId
              );
              if (foundService) {
                //this.serviceControl.updateValueAndValidity();
                this.serviceControl.setValue(foundService, {
                  emitEvent: false,
                });
                this.routeUpdated.emit({
                  route: foundService.route,
                  routeEncodingVersion: foundService.routeEncodingVersion,
                });
                let foundPricing = null;
                if (servicePriceItem) {
                  // foundPricing = foundService.prices.find(
                  //   (s) => s.pricingMethodId == servicePriceItem.pricingMethodId
                  // );
                  foundPricing = foundService.prices.find(
                    (s) =>
                      s.pricingMethodType == servicePriceItem.pricingMethodType
                  );
                }

                if (!foundPricing) {
                  foundPricing = foundService.prices[0];
                }
                this.pricingControl.setValue(foundPricing);

                // this.pricingControl.setValue(foundPricing, {
                //   emitEvent: false,
                // });
              } else {
                this.setDefaultService();
              }
            } else {
              this.setDefaultService();
            }
            this.changeDetectorRef.markForCheck();
          },
          error: (issues: IssueModel[]) => {
            this.issues = issues;
            this.loading = false;
            this.services = [];
            this.previousParams = null;
            this.serviceControl.setValue(null, { emitEvent: false });
            this.changeDetectorRef.markForCheck();
          },
        });
      }
    } else {
      this.services = [];
      this.showList = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  setDefaultService() {
    if (this.services.length === 1) {
      this.serviceControl.setValue(this.services[0]);
    } else if (this.services.length > 0) {
      const defaultService = this.services.find(
        (s) => s.defaultService == true
      );
      if (defaultService) {
        this.serviceControl.setValue(
          this.services.find((s) => s.defaultService == true)
        );
      } else {
        this.serviceControl.setValue(null);
        this.showList = true;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  serviceSelected(service: ServicePriceModel) {
    this.showList = false;
    this.serviceControl.setValue(service);
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    // this.hostFocused = true;

    setTimeout(() => {
      this.hostRef.nativeElement.focus();
    }, 100);
  }

  setServiceAndPrice(service: ServicePriceModel, price: ServicePriceItemModel) {
    this.routeUpdated.emit({
      route: service.route,
      routeEncodingVersion: service.routeEncodingVersion,
    });
    this.serviceControl.setValue(service, { emitEvent: false });
    this.pricingControl.setValue(price, { emitEvent: false });
    this.onChange({
      service: service,
      pricing: price,
      totalTariff: this.totalTariffControl.value,
      primaryTariffPayment: this.primaryTariffControl.value,
    });
    this.onTouched();
    this.showList = false;
    this.hidePricingOptions = true;
    this.changeDetectorRef.markForCheck();
  }

  writeValue(serviceWithPrice: ServiceWithPricingItem): void {
    if (serviceWithPrice) {
      if (this.servicePrices && this.servicePrices.length > 0) {
        const foundServicePrice = this.servicePrices.find(
          (s) =>
            s.service.networkServiceId ===
              serviceWithPrice.service.networkServiceId &&
            s.pricing.pricingMethodId ===
              serviceWithPrice.pricing.pricingMethodId
        );
        if (foundServicePrice) {
          serviceWithPrice = {
            ...foundServicePrice,
            totalTariff: serviceWithPrice.totalTariff,
            primaryTariffPayment: serviceWithPrice.primaryTariffPayment,
          };
        }
      }

      this.serviceControl.setValue(serviceWithPrice.service, {
        emitEvent: false,
      });
      this.pricingControl.setValue(serviceWithPrice.pricing, {
        emitEvent: false,
      });
      if (serviceWithPrice.totalTariff > 0) {
        this.totalTariffControl.setValue(serviceWithPrice.totalTariff, {
          emitEvent: false,
        });

        if (serviceWithPrice.primaryTariffPayment > 0) {
          this.primaryTariffControl.setValue(
            serviceWithPrice.primaryTariffPayment
          );
          this.flatFareSplit = true;
        } else {
          this.primaryTariffControl.setValue(null);
          this.flatFareSplit = false;
        }
      } else {
        this.totalTariffControl.setValue(null, {
          emitEvent: false,
        });
        this.primaryTariffControl.setValue(null, {
          emitEvent: false,
        });
        this.flatFareSplit = false;
      }
      this.changeDetectorRef.markForCheck();
    } else {
      this.serviceControl.setValue(null, { emitEvent: false });
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.serviceControl.disable({ emitEvent: false });
      this.totalTariffControl.disable({ emitEvent: false });
      this.primaryTariffControl.disable({ emitEvent: false });
    } else {
      this.serviceControl.enable({ emitEvent: false });
      this.totalTariffControl.enable({ emitEvent: false });
      this.primaryTariffControl.enable({ emitEvent: false });
    }
  }

  prepareParamsForServiceSearch(
    startLocation: LocationModel,
    startTime: DateTime,
    endLocation?: LocationModel,
    waypoints?: LocationModel[],
    serviceLineId?: string,
    numberOfTravelers?: number,
    jobOrganisation?: JobOrganisationModel
  ) {
    const cleanedStartTime = startTime
      .set({ second: 0, millisecond: 0 })
      .toISO({ includeOffset: true });
    const params = {
      startLocation: startLocation.latitude + '|' + startLocation.longitude,
      startTime: cleanedStartTime,
    } as any;
    if (endLocation && endLocation.latitude) {
      params['endLocation'] =
        endLocation.latitude + '|' + endLocation.longitude;
    }
    if (
      waypoints?.filter(
        (waypoint: LocationModel) => waypoint?.latitude !== null
      ).length > 0
    ) {
      params['waypointLocation'] = waypoints
        .filter(
          (waypoint: LocationModel) =>
            waypoint.latitude !== null && waypoint.latitude !== undefined
        )
        .map(
          (waypoint: LocationModel) =>
            waypoint.latitude + '|' + waypoint.longitude
        )
        .join(',')
        .toString();
    }
    if (this.networkGroupId) {
      params['networkGroupId'] = this.networkGroupId;
    }
    if (serviceLineId) {
      params['serviceLineId'] = serviceLineId;
    }

    if (numberOfTravelers) {
      params['travellers'] = numberOfTravelers;
    }
    if (jobOrganisation?.organisationId) {
      params['organisationId'] = jobOrganisation.organisationId;
      if (jobOrganisation.organisationGroupId) {
        params['organisationGroupId'] = jobOrganisation.organisationGroupId;
      }
    }
    return params;
  }

  compareServicePrices(object1: ServicePriceModel, object2: ServicePriceModel) {
    return (
      object1 && object2 && object1.networkServiceId == object2.networkServiceId
    );
  }

  compareServicePriceItems(
    object1: ServicePriceItemModel,
    object2: ServicePriceItemModel
  ) {
    return (
      object1 && object2 && object1.pricingMethodId === object2.pricingMethodId
    );
  }

  openList() {
    this.showList = true;
  }

  toggleList() {
    this.showList = !this.showList;
    if (this.showList) {
      this.hidePricingOptions = false;
    }
    this.changeDetectorRef.markForCheck();

    if (this.showList && this.services.length > 0) {
      // setTimeout(() => {
      //   this.serviceList.focus();
      // }, 100);

      setTimeout(() => {
        this.serviceList.focus();
        if (this.serviceControl.value) {
          const selectedService = (this.serviceList as any).options.find(
            (s: any) =>
              s.value.networkServiceId ==
              this.serviceControl.value.networkServiceId
          );
          if (selectedService) {
            selectedService.focus();
          } else {
            (this.serviceList as any).options.first.focus();
          }
        } else {
          (this.serviceList as any).options.first.focus();
        }
      }, 100);
    }
  }

  selectPrice(price: ServicePriceItemModel) {
    this.pricingControl.setValue(price);
  }

  checkSplitFare(isSplit: boolean) {
    this.flatFareSplit = isSplit;
    if (isSplit) {
      this.primaryTariffControl.setValidators([
        Validators.required,
        Validators.min(0.01),
        Validators.max(this.totalTariffControl.value),
      ]);
      this.primaryTariffControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  reset() {
    this.previousParams = null;
    this.flatFareSplit = false;
    this.totalTariffControl.reset(null, { emitEvent: false });
    this.primaryTariffControl.reset(null, { emitEvent: false });
    this.pricingControl.reset(null, { emitEvent: false });
    this.serviceControl.reset(null, { emitEvent: false });
  }

  openExternalPricingLink() {
    //websiteUrl + /taxi-fares
    if (this.networkGroupUrl) {
      window.open(this.networkGroupUrl + '/taxi-fares', '_blank');
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
