import { FLEET_VERSION } from '@fleet/version';
export const environment = {
  production: true,
  hmr: false,
  xApiKey: 'pk_test_979473aac2e1c7b4dc7f85430ba00001',
  host: 'https://preprod-tapi-demo1.us1.fleet-dev.com',
  googleApiKey: 'AIzaSyDOOk5iG_N8crmcgz_fvooLtY3C9hSIVwA',
  minTokenExpiry: 60,
  fleetProduct: 'TRAVELLER',
  envName: 'demo1-us1',
  socketHost: 'wss://preprod-svc-socket.us1.fleet-dev.com/socket',
  socketTopicPrefix: '/topic/demo1/core/v3/',
  socketAcknowledge:
    'https://preprod-svc-socket.us1.fleet-dev.com/socket/client/{{clientId}}/acknowledge/',
  tasHost: 'https://preprod-svc-tas.us1.fleet-dev.com',
  version: FLEET_VERSION,
};
